import styled from 'styled-components';


export const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: ${ ( { theme } ) => theme.spacing05 };
    margin-bottom: ${ ( { theme } ) => theme.spacing02 };
    
    img {
        max-height: 500px;
        position: relative;
        border-radius: 60px 0 0 0;
        z-index: ${ ( { theme } ) => theme.floor01 };
    }
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        flex-direction: column;
    }
`;

export default ImageWrapper;
