import styled from 'styled-components';

import Avatar from '@/components/Avatar';

const PreviewImage = styled.div`
    margin-bottom: ${ ( { theme } ) => theme.spacing08 };

    ${ Avatar } {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: scale(0.8) translate(-50%, 0);
        margin: 0;
    }

    > img {
        width: 100%;
        min-height: 150px;
        border-radius: ${ ( { theme } ) => `${ theme.borderRadiusM } ${ theme.borderRadiusM } 0 0` };
    }
`;

export default PreviewImage;
