import React from 'react';

import {
    FeatureBoxContent,
    FeatureBoxDecor,
    FeatureBoxImage,
    FeatureBoxLink,
    FeatureBoxText,
    FeatureBoxTitle,
    FeatureBoxWrapper
} from '../styled';

const FeatureBox = ( { as, dataId, dataValue, title, text, img, link, isFeatured = false } ) => (
    <FeatureBoxWrapper data-id={dataId} data-value={dataValue} as={as} href={ link } target="_blank">
        <FeatureBoxContent isFeatured={ isFeatured }>
            { img && <FeatureBoxImage>{ img }</FeatureBoxImage> }
            { title && link && <FeatureBoxLink>{ title }</FeatureBoxLink> }
            { title && !link && <FeatureBoxTitle isFeatured={ isFeatured }>{ title }</FeatureBoxTitle> }
            { text && <FeatureBoxText>{ text }</FeatureBoxText> }
        </FeatureBoxContent>
        { isFeatured && <FeatureBoxDecor/> }
    </FeatureBoxWrapper>
);

export default FeatureBox;
