import { ebookStats } from '@/constants';

export const translations = {
    advantagesSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    advantagesHeadline: 'Tysiące Podróżniczych Korzyści',

    advantagesItemTitle01: 'Różne Kierunki',
    advantagesItemText01: `Inspiracje na ${ ebookStats.EBOOK_TOTAL_DESTINATIONS } destynacji`,

    advantagesItemTitle02: `Oszczędzasz ${ ebookStats.EBOOK_SAVING }`,
    advantagesItemText02: `Wyjątkowa wartość, w zaskakująco niskiej cenie`,

    advantagesItemTitle03: `${ ebookStats.EBOOK_TOTAL_RECOMMENDATION } Polecajek`,
    advantagesItemText03: 'Setki miejsc, które zostały sprawdzone przez podróżników',

    advantagesItemTitle04: `${ ebookStats.EBOOK_TOTAL_EBOOKS } e-booków`,
    advantagesItemText04: `Kompletna biblioteka podróżnicza w zasięgu ręki`,
};

export default translations;
