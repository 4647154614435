import React from 'react';

import { Col, Row } from '@/components/Layout';
import Headline from '@/components/Headline';
import Spacer from '@/components/Spacer';
import Section from '@/components/Section';
import Text from '@/components/Text';
import translations from '../constants/translations';

import { FaqHeadline } from '../styled';

const FaqList = () => (
    <Section variant="dark">
        <Headline>
            { translations.faqHeadline }
        </Headline>
        <Row>
            <Col number={ 1 } alignItems='left'>
                { translations.faqList.map( ( ( item, index ) => {
                    return (
                        <>
                            <FaqHeadline>
                                { item.question }
                            </FaqHeadline>
                            <Text size="medium">
                                <span
                                    dangerouslySetInnerHTML={ { __html:  item.answer } }
                                />
                            </Text>
                        </> );
                } ) ) }

            </Col>
        </Row>
        <Spacer size="medium" />
    </Section>
);


export default FaqList;
