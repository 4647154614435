import styled from 'styled-components';
import { getColor } from '../utils';

const SectionWrapper = styled.section`
    position: relative;
    margin: 0 auto;
    padding: 0;
    background: ${ ( { variant, theme } ) => getColor( { variant, theme } ) };
    display: flex;
    align-items: center;
`;

export default SectionWrapper;
