export const colorPalette = {
    white: '#ffffff',
    blue100: '#5e6282',
    blue300: '#181e4b',
    gray100: '#faf9f9',
    gray200: '#a4a4a4',
    gray300: '#555555',
    yellow100: '#ffa751',
    yellow300: '#ffa751',
    orange300: '#d9634b',
};

export default colorPalette;
