import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const useWindowResize = () => {
    const theme = useTheme();
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= theme.mobileBreakPoint);

    useEffect(() => {
        const handleResize = () => {
            setResolution((prevResolution) => ({
                width: window.innerWidth,
                height: window.innerHeight,
            }));

            setIsMobile(window.innerWidth <= theme.mobileBreakPoint);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [theme.mobileBreakPoint]);

    return { resolution, isMobile };
};

export default useWindowResize;