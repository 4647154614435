import styled from 'styled-components';


export const SummaryItem = styled.div`
    text-align: left;
    color: ${ ( { theme } ) => theme.text02 };
    font-size: ${ ( { theme } ) => theme.fontSizeXL };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        margin-bottom: ${ ( { theme } ) => theme.spacing05 };
        align-items: center;
    }
`;

export default SummaryItem;
