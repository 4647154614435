export const setFeatureFlag = ( featureName, value ) => {
    try {
        // Retrieve the existing feature flags from local storage
        const existingFeatureFlagsString = localStorage.getItem( 'featureFlags' );

        // Parse the JSON string to an object
        const existingFeatureFlags = JSON.parse( existingFeatureFlagsString ) || {};

        // Update or add the specified feature with the provided value
        existingFeatureFlags[ featureName ] = value;

        // Convert the updated feature flags to a JSON string
        const updatedFeatureFlagsString = JSON.stringify( existingFeatureFlags );

        // Set the updated feature flags back to local storage
        localStorage.setItem( 'featureFlags', updatedFeatureFlagsString );
    } catch ( error ) {
        // Handle any errors that might occur during the process
        console.error( 'Error setting feature flag:', error );
    }
};

export default setFeatureFlag;
