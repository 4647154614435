import React from 'react';

import Text from '@/components/Text';
import Button from '@/components/Button';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';
import FeatureBox from '@/components/FeatureBox';

import { ReactComponent as PiggyIcon } from '@/assets/piggy_icon.svg';
import { ReactComponent as BookIcon } from '@/assets/book_icon.svg';

import { getBundlePrice, getRegularPrice, isButtonBuyDisabled, isPromotionCompleted } from '@/utils';
import { ebookStats, globals, paths, routes, translations } from '@/constants';

import { BannerBigText, BannerImageWrapper, PricingHeadline, PricingPrice } from '../styled';
import PricingContent from './PricingContent';

const Banner = () => {
    // const hideBuyButton = isButtonBuyDisabled() || isPromotionCompleted();
    const hideBuyButton = isButtonBuyDisabled();

    return (
        <Section spacing="small" variant="transparent">
            <Row justifyContent="flex-start">
                <Col number={ 2 } alignItems="flex-start">
                    <Headline align="left" type="primary">
                        { translations.bannerHeadline01 }
                        <em>{ translations.bannerHeadline02 }</em>
                    </Headline>
                    <br/>
                    <Text size="medium">{ translations.bannerText01 }
                        <BannerBigText>{ ebookStats.EBOOK_TOTAL_EBOOKS }</BannerBigText> { translations.bannerText02 }
                    </Text>
                    <Text size="medium">{ translations.bannerText03 }</Text>
                    <Row justifyContent="center">
                        <Col number={ 2 } alignItems="center">
                            <FeatureBox
                                img={ <PiggyIcon/> }
                                title={
                                    <>
                                        <PricingHeadline>{ translations.pricingHeadlineItem01 }</PricingHeadline>
                                        <PricingPrice>{ getBundlePrice }</PricingPrice>
                                    </>
                                }
                                text={
                                    <>
                                        <PricingContent/>
                                        <Button
                                            as="a"
                                            rel="nofollow"
                                            dataId="banner-button-buy"
                                            href={ !hideBuyButton ? routes.TRAVEL_PACK_URL_BUY : undefined }
                                            isDisabled={ hideBuyButton }
                                        >
                                            { translations.bannerButtonText }
                                        </Button>
                                    </>
                                }
                            />
                        </Col>
                        <Col number={ 2 } alignItems="flex-start">
                            <FeatureBox
                                img={ <BookIcon/> }
                                title={
                                    <>
                                        <PricingHeadline>{ translations.pricingHeadlineItem02 }</PricingHeadline>
                                        <PricingPrice>{ getRegularPrice }</PricingPrice>
                                    </>
                                }

                                text={ <PricingContent/> }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col number={ 2 }>
                    <BannerImageWrapper>
                        <img src={ `${ paths.ASSETS }/banner.png` } alt={ globals.SITE_NAME }/>
                    </BannerImageWrapper>
                </Col>
            </Row>
        </Section>
    );
};


export default Banner;
