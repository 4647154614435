import React from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import StickyCountdown from '@/components/StickyCountdown';

import Banner from 'src/views/Banner';
import Confirmation from 'src/views/Confirmation'

export const CheckoutSuccess = () => (
    <>
        <Header/>
        <Main>
            <Banner/>
            <Confirmation />
        </Main>
        <Footer/>
        <StickyCountdown/>
    </>
);


export default CheckoutSuccess;