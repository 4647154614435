import styled from 'styled-components';


export const FaqHeadline = styled.h2`
    text-align: left;
    color: ${ ( { theme } ) => theme.text02 };
    font-size: ${ ( { theme } ) => theme.fontSizeM };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-family: ${ ( { theme } ) => theme.fontFamilySecondary };
  
`;

export default FaqHeadline;
