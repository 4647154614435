import React from 'react';

import { HeadlineText, HeadlineWrapper, SubHeadingText } from '../styled';

const Headline = ( { type = 'secondary', align = 'center', subHeading, children } ) => {
    return (
        <HeadlineWrapper align={ align }>
            { subHeading && <SubHeadingText>{ subHeading }</SubHeadingText> }
            <HeadlineText type={ type } as={ type === 'secondary' && 'h2' }> { children }</HeadlineText>
        </HeadlineWrapper>
    );
};

export default Headline;
