import styled from 'styled-components';

import FeatureBoxTitle from './FeatureBoxTitle';

const FeatureBoxLink = styled( FeatureBoxTitle )`
    white-space: nowrap;
    text-overflow: ellipsis;;
    overflow: hidden;
    &:hover {
        color: ${ ( { theme } ) => theme.ui07 }
    }
`;

export default FeatureBoxLink;
