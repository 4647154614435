import styled from 'styled-components';


export const SummaryImage = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 800px;
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        display: none;
    }
`;

export default SummaryImage;
