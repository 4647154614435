import styled from 'styled-components';


export const CommentWrapper = styled.div`
    display: flex;
    flex-direction: ${ ( { orientation } ) => orientation === 'left' ? 'row' : 'row-reverse' };;
    margin-bottom: ${ ( { theme } ) => theme.spacing05 };
    align-items: flex-end;
`;

export default CommentWrapper;
