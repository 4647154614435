import React from 'react';

import { modules, routes, globals } from '@/constants';
import { useWindowResize } from '@/hooks';
import {
    FooterContent,
    FooterCopyright,
    FooterNavigation,
    FooterNavigationItem,
    FooterNavigationLink,
    FooterNavigationList,
    FooterWrapper,
} from '../styled';
import Logotype from '../../Logotype';

const Footer = ( { items } ) => {
    const { isMobile } = useWindowResize();
    const footerRoutes = routes.NAVIGATION.filter( ( route ) => {
        const [ , routeObject ] = Object.entries( route )[ 0 ]; // Extract the inner route object
        if ( isMobile ) {
            return null;
        }
        return routeObject.module.includes( modules.MODULE_FOOTER );
    } );

    return (
        <FooterWrapper>
            <FooterContent>
                <Logotype isFullLogotype={ false }/>
                <FooterNavigation>
                    <FooterNavigationList>
                        { footerRoutes.map( ( route, index ) => {
                            const key = Object.keys( route )[ 0 ];
                            const { url, label, isExternal } = route[ key ];

                            return (
                                <FooterNavigationItem key={ index }>
                                    <FooterNavigationLink
                                        as="a" href={ url }
                                        target={ isExternal ? '_blank' : '_self' }
                                        data-id="navigation-link-footer"
                                    >
                                        { label }
                                    </FooterNavigationLink>
                                </FooterNavigationItem>
                            );
                        } ) }
                    </FooterNavigationList>
                </FooterNavigation>
            </FooterContent>
            <FooterCopyright>
                Designed by {globals.SITE_NAME} | Graphics: <a href="https://www.freepik.com" target="_blank"
                                                       rel="noreferrer">www.freepik.com</a>
            </FooterCopyright>
        </FooterWrapper>
    );
};

export default Footer;
