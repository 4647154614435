import styled from 'styled-components';


const FeatureBoxImage = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 64px;
  width: 64px;
  border-radius: 100%;
  text-align: center;
`;

export default FeatureBoxImage;
