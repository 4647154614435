import styled from 'styled-components';


const FooterNavigation = styled.nav`
    display: flex;
    margin: 0 0 0 ${ ( { theme } ) => theme.spacing06 };
    flex-direction: row;
    align-items: center;
`;

export default FooterNavigation;
