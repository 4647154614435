import styled from 'styled-components';


export const InfoBox = styled.div`
    padding: ${ ( { theme } ) => theme.spacing07 };
    border-radius: ${ ( { theme } ) => theme.borderRadiusS };
    border: 1px solid ${ ( { theme } ) => theme.ui08 };
    margin-bottom: ${ ( { theme } ) => theme.spacing05 };
`;

export default InfoBox;
