import styled from 'styled-components';


const PreviewText = styled.p`
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    font-weight: ${ ( { theme } ) => theme.fontWeightRegular };
    text-align: left;
    color: ${ ( { theme } ) => theme.text01 };
    padding: 0;
    margin: 0;
        //margin: 0 0 ${ ( { theme } ) => theme.spacing07 } ${ ( { theme } ) => theme.spacing07 };
`;

export default PreviewText;
