import styled from 'styled-components';
import { getColor } from '../utils';

export const ButtonWrapper = styled.button.attrs(props => ({
    disabled: props.isDisabled
}))`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    outline: none;
    font-size: ${ ( { theme } ) => theme.fontSizeXS };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    background: ${ ( { theme, variant, isDisabled } ) => getColor( { theme, variant, isDisabled } ).backgroundColor };
    text-transform: uppercase;
    text-decoration: none;
    color: ${ ( { theme, variant, isDisabled } ) => getColor( { theme, variant, isDisabled } ).color };
    border: ${ ( { variant } ) => variant === 'secondary' ? '2px solid currentColor' : '0' };
    border-radius: ${ ( { theme } ) => theme.borderRadiusS };
    cursor: ${ ( { isDisabled } ) => isDisabled ? 'cursor' : 'pointer' };
    transition: all ${ ( { theme } ) => `${ theme.transitionTypeDefault } ${ theme.transitionFast }` };
    padding: 0;
    white-space: nowrap;
    width: ${ ( { isFullWidth } ) => isFullWidth ? '100%' : 'auto' };

    &:not([disabled]):hover  {
        color: ${ ( { theme, variant } ) => theme.text03 };
        background: ${ ( { theme, variant } ) => variant === 'secondary' ? theme.ui05 : theme.ui06 };
    }

`;

export default ButtonWrapper;
