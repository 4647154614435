import React from 'react';

import Text from '@/components/Text';
import Section from '@/components/Section';
import Spacer from '@/components/Spacer';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';
import { globals, paths } from '@/constants';
import { EBOOK_TOTAL_DESTINATIONS, EBOOK_TOTAL_PAGES, EBOOK_TOTAL_RECOMMENDATION } from '@/constants/ebookStats';

import { SummaryItem, SummarySubItem, SummaryWrapper, SummaryImage} from '../styled';
import translations from '../constants/translations';

const Summary = () => (
    <Section>
        <Row>
            <Col number={ 2 }>
                <Headline align="left">
                    { translations.summaryHeadline01 }
                    <em>{ translations.summaryHeadline02 }</em>
                </Headline>
                <br/>
                <br/>
                <Text size="medium">
                    { translations.summaryTextMain01 }
                    <Spacer size="super-small" />
                    { translations.summaryTextMain02 }
                </Text>
                <SummaryWrapper>
                    <SummaryItem>
                        { EBOOK_TOTAL_DESTINATIONS }
                        <SummarySubItem>  { translations.summaryItemText01 }</SummarySubItem>
                    </SummaryItem>
                    <SummaryItem>
                        { EBOOK_TOTAL_PAGES }
                        <SummarySubItem>{ translations.summaryItemText02 }</SummarySubItem>
                    </SummaryItem>
                    <SummaryItem>
                        { EBOOK_TOTAL_RECOMMENDATION }
                        <SummarySubItem>{ translations.summaryItemText03 }</SummarySubItem>
                    </SummaryItem>
                </SummaryWrapper>
            </Col>
            <Col number={ 2 }>
                <SummaryImage src={ `${ paths.ASSETS }/summary_bg.png` } alt={ globals.SITE_NAME }/>
            </Col>
        </Row>
    </Section>
);


export default Summary;
