import React from 'react';

import Avatar from '@/components/Avatar';
import { CommentSpeechBubble, CommentSpeechBubbleRight, CommentWrapper } from '../styled';

const Comment = ( {
                      avatar,
                      orientation = 'left',
                      text,
                  } ) => (
    <CommentWrapper
        orientation={ orientation }
    >
        <Avatar title="Awatar">
            { avatar }
        </Avatar>
        { orientation === 'left' ? <CommentSpeechBubble>{ text }</CommentSpeechBubble> :
            <CommentSpeechBubbleRight>{ text }</CommentSpeechBubbleRight> }
    </CommentWrapper> );

export default Comment;
