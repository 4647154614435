export const ebooksList = [
    {
        "author": "before.we.get.old",
        "profileLink": "https://www.instagram.com/before.we.get.old/",
        "eBookTitle": "Austria Na Rowerze. Szlakiem Jezior Górskich",
        "pages": 49,
        "regularPrice": 49.9,
        "location": "Austria",
        "ebookDescription": "Przewodnik z najpiękniejszymi trasami rowerowymi Górnej Austrii.<br/><br/>-Gotowy plan 5-dniowej wycieczki rowerowej do Austrii.<br/>- Mapa trasy na każdy dzień do otworzenia na komputerze lub w aplikacji (wystarczy kliknąć NAWIGUJ i jechać).<br/>- Opis najciekawszych atrakcji w rejonie.<br/>- Porady praktyczne (dojazd do Austrii, logistyka na miejscu, polecane campingi, wypożyczalnie rowerów na trasie, kosztorys wyjazdu).<br/>- Atrakcje dla rodzin z dziećmi.<br/>- Najpiękniejsze punkty widokowe.<br/>- Co robić w deszczowy dzień?",
        "img": "before.we.get.old_cover_austria.jpg",
        "profileImg": "before.we.get.old_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "before.we.get.old",
        "profileLink": "https://www.instagram.com/before.we.get.old/",
        "eBookTitle": "Praga Na Weekend",
        "pages": 49,
        "regularPrice": 49.9,
        "location": "Praga",
        "ebookDescription": "Praga na weekend to wyjątkowy przewodnik, w którym znajdziesz:<br /><br />- Gotowy do druku plan zwiedzania Pragi w 3 dni,<br />- Mapa trasy na każdy dzień (wersja do druku + pliki do wgrania do aplikacji maps.me)<br />- Szczegółowy kosztorys 3-dniowego wyjazdu,<br />- Wskazówki jak dotrzeć z lotniska do centrum,<br />- Instrukcja poruszania się komunikacją miejską (np. jaki rodzaj biletów kupić i jak je aktywować),<br />lista polecanych noclegów w dogodnej logistycznie lokalizacji (z podziałem na zakresy cenowe).",
        "img": "before.we.get.old_cover_praga.jpg",
        "profileImg": "before.we.get.old_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "marta_lofotengirl",
        "profileLink": "https://www.instagram.com/marta_lofotengirl/",
        "eBookTitle": "Lato #nalofotach",
        "pages": 64,
        "regularPrice": 67.6,
        "location": "Lofoty",
        "ebookDescription": "Po 13 latach mieszkania na wyspach, przelałam Wam moją wiedzę o tym miejscu w przewodniki lofockie. To gotowy interaktywny produkt, który bardzo ułatwi Wam przemieszczenie się po wyspach i sprawi, że ten pobyt będzie łatwiejszy. To plan dla tych którzy:<br /><br />- przyjeżdzają na Lofoty nie wiedzą zbytnio od czego zacząć,<br />- chcieliby mieć gotowy, zaplanowany, tydzień na wyspach,<br />-szukają planu, który wypełni im każdy dzień od rana do wieczora,<br />- chcieliby poznać najlepsze miejscówki lofockie, te znane ale i te mniej oblegane turystycznie. <br /><br />Oddaje w Wasze ręce gotowy produkt, wraz z mapą Google, na której zaznaczone są:<br /><br />- początki szlaków,<br />- restauracje,<br />- noclegi,<br />- stacje benzynowe, sklepy, plaże<br />- lokalne farmy ze zwierzętami,<br />- placówki lekarskie, apteki, szpital.",
        "img": "marta_lofotengirl_lato_cover.jpg",
        "profileImg": "marta_lofotengirl_avatar.jpeg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "marta_lofotengirl",
        "profileLink": "https://www.instagram.com/marta_lofotengirl/",
        "eBookTitle": "Zima #nalofotach",
        "pages": 64,
        "regularPrice": 67.6,
        "location": "Lofoty",
        "ebookDescription": "Po 13 latach mieszkania #nalofotach, przelałam Wam moją wiedzę o tym miejscu w przewodniki lofockie. To gotowy interaktywny produkt, który bardzo ułatwi Wam przemieszczenie się po wyspach i sprawi, że ten pobyt będzie łatwiejszy. To plan dla tych którzy:<br /> <br />- przyjeżdzają zimą na Lofoty nie wiedzą zbytnio od czego zacząć,<br />- chcieliby mieć gotowy, zaplanowany, tydzień na wyspach,<br />- szukają planu, który wypełni im każdy dzień od rana do wieczora,<br />- chcieliby poznać najlepsze miejscówki lofockie, te znane ale i te mniej oblegane turystycznie.<br /> <br />W tym e-book’u nie tylko napisałam Wam plan podróży, ale podzieliłam się z Wami moimi najlepszymi noclegami, i restauracjami. Dodatkowo stworzyłam dla Was mapę Google z zaznaczonymi miejscówkami.",
        "img": "marta_lofotengirl_zima_cover.jpg",
        "profileImg": "marta_lofotengirl_avatar.jpeg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "marta_lofotengirl",
        "profileLink": "https://www.instagram.com/marta_lofotengirl/",
        "eBookTitle": "Lato #naLofotach Z Dziećmi - 7 Dniowy Letni Plan Podróży Po Archipelagu",
        "pages": 52,
        "regularPrice": 55.6,
        "location": "Lofoty",
        "ebookDescription": "7 dniowy plan podróży po archipelagu lofockim dla rodzin z dziećmi.\nTo gotowy interaktywny produkt, który bardzo ułatwi Wam przemieszczenia się po wyspach i sprawi, że ten pobyt będzie łatwiejszy.\n\nTo plan dla tych którzy:\n\n- Przyjeżdzają na Lofoty z dziećmi i nie wiedzą zbytnio gdzie je zabrać\n- Chcieliby mieć gotowy, zaplanowany, tydzień na wyspach\n- Szukają planu, który wypełni im każdy dzień od rana do wieczora\n- Chcieliby poznać najlepsze miejscówki lofockie",
        "img": "marta_lofotengirl_dzieci_cover.jpg",
        "profileImg": "marta_lofotengirl_avatar.jpeg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "traveliway",
        "profileLink": "https://www.instagram.com/traveliway/",
        "eBookTitle": "TraveliBook – Jak Podróżować I Nie Zwariować",
        "pages": 170,
        "regularPrice": 89,
        "location": "",
        "ebookDescription": "Kompendium wiedzy na temat samodzielnej organizacji podróży:<br /><br />- proces organizacji podróży od A do Z, krok po kroku!<br />- kilkadziesiąt aplikacji, które przydadzą się w podróży<br />- kilkadziesiąt witryn, dzięki którym znajdziesz najlepsze okazje i przestaniesz przepłacać za usługi!<br />- wypowiedzi specjalistów<br />- przydatne gadżety<br />- tricki na tańsze loty czy noclegi!<br />- zniżki na przewodniki, noclegi, obozy itd.",
        "img": "traveliway_ebook_cover.jpg",
        "profileImg": "traveliway_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "dokadtymrazemm",
        "profileLink": "https://www.instagram.com/dokadtymrazemm/",
        "eBookTitle": "Tanie Podróżowanie - Naucz Się Od Podstaw",
        "pages": 115,
        "regularPrice": 49,
        "location": "",
        "ebookDescription": "Chcesz zacząć podróżować budżetowo? Masz problem z zorganizowaniem wyjazdu na własną rękę? A może chcesz przeżyć przygodę jaką jest podróż solo?<br /><br /> W takim razie ten poradnik jest dla Ciebie – zawarte zostały w nim same konkrety, ukryte tipy, o których pierwszy raz usłyszysz i praktyczna wiedza jaką zbierałam przez ostatnie 3 lata ciągłej podróży! I Ty możesz nauczyć się taniego podróżowania.",
        "img": "dokadtymrazemm_cover.jpg",
        "profileImg": "dokadtymrazemm_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "skyeenika",
        "profileLink": "https://www.instagram.com/skyeenika/?hl=en",
        "eBookTitle": "Neapol Do Pokochania - Spacer Po Moich Ulubionych Miejscach",
        "pages": 134,
        "regularPrice": 39.99,
        "location": "Neapol",
        "ebookDescription": "Neapol to miasto pełne kontrastów, w którym jednak można zakochać się już od pierwszego wejrzenia. Zapraszam Cię na spacer po moich ulubionych miejscach. Z tego ebooka dowiesz się, w których dzielnicach warto nocować, gdzie zjesz najlepszą pizzę, skąd rozciąga się najlepsza panorama na miasto... I wiele więcej!",
        "img": "skyeenika_cover.jpg",
        "profileImg": "skyeenika_avatar.jpeg",
        "rank": 10,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "podrozovanie",
        "profileLink": "https://www.instagram.com/podrozovanie/",
        "eBookTitle": "Wyprawa do Maroka – Poradnik Podróży",
        "pages": 347,
        "regularPrice": 59,
        "location": "Maroko",
        "ebookDescription": "Jak zorganizować własną wyprawę do Maroka? Czy jest tam bezpiecznie? Czy to prawda, że kobieta musi zakrywać włosy? \n\nW tej książce znajdziesz odpowiedzi na wszystkie nurtujące Cię przed wyjazdem pytania. Podpowiemy, co warto spakować, gdzie szukać noclegu, ale przede wszystkim pomożemy Ci uniknąć szoku kulturowego, przygotowując Cię na rzeczywistość, którą zastaniesz na miejscu.\n\nTę książkę dedykujemy głównie tym, którzy planują po raz pierwszy wybrać się do Maroka kamperem lub samochodem. Jednak będzie ona również ciekawą pozycją dla osób, które chcą dowiedzieć się więcej o tym pięknym, różnorodnym kraju.",
        "img": "podrozovanie_cover.jpg",
        "profileImg": "podrozovanie_avatar.jpeg",
        "rank": 3,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "kamperowetrasy.pl",
        "profileLink": "https://www.instagram.com/kamperowetrasy.pl/",
        "eBookTitle": "Plan Podróży Po Włoszech – 3 Gotowe Trasy",
        "pages": 249,
        "regularPrice": 49,
        "location": "Włochy",
        "ebookDescription": "W e-booku znajdziesz plan trzech niezależnych tras podróży, obejmujących północno-środkową część Włoch oraz dwa miejsca nad jeziorami w Austrii. Przewodnik jest pełen praktycznych wskazówek i świetnych miejsc!<br /><br />Dobrze zaplanowana wyprawa to nie tylko oszczędność Twojego czasu ale również gwarancja Twoich udanych wakacji!<br />Gotowe trasy podróży w tym e-booku poprowadzą Cię po kolei przez najpiękniejsze miejsca we Włoszech. Dzięki temu będziesz mieć możliwość odwiedzenia wielu atrakcyjnych i znanych miast takich jak Mediolan, Wenecja, Rzym, Florencja.  Podczas podróży zajedziesz również do mniej znanych, ale  podziwianych miasteczek – istnych perełek. Do tego zobaczysz pełne uroku Toskanię, Elbę, Ligurię oraz dziewicze Parki Przyrody. Nie zabraknie też hipnotycznych miejsc nad jeziorami w górach – Garda i Como czekają na Ciebie! A tłem do tych wszystkich fajnych miejsc będą kąpiele zarówno słoneczne jak i morskie (lub jeziorne).",
        "img": "kamperowetrasy.pl_cover.jpg",
        "profileImg": "kamperowetrasy.pl_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "joanna.tobola",
        "profileLink": "https://www.instagram.com/joanna.tobola/",
        "eBookTitle": "Przewodnik Rozwojowy - Zmiana Pracy",
        "pages": 180,
        "regularPrice": 49.99,
        "location": "",
        "ebookDescription": "W Przewodniku Rozwojowym – Zmiana pracy zajmuję się tematem związanym ze zmianami zawodowymi od strony towarzyszących im rozważań, emocji i obaw. Przygotowane ćwiczenia pomogą Ci zweryfikować, czy w ogóle potrzebujesz zmiany i przygotować się do niej. Znajdziesz tu konkretne rady dotyczące tworzenia skutecznego CV oraz tego, o co należy zadbać przy poszukiwaniu nowej pracy.",
        "img": "joanna.tobola_cover.jpg",
        "profileImg": "joanna.tobola_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "lauragotowska",
        "profileLink": "https://www.instagram.com/lauragotowska",
        "eBookTitle": "Podstawy Podróżowania",
        "pages": 56,
        "regularPrice": 50,
        "location": "",
        "ebookDescription": "Odkryj samodzielne podróżowanie dzięki mojemu e-bookowi! Zebrałam esencję niezbędnej wiedzy, aby umożliwić Ci podróżowanie z ograniczonym budżetem, nie tracąc przy tym na jakości. W tym poradniku znajdziesz kluczowe informacje, jak efektywnie szukać tanich lotów, pakować się z głową, wybierać ubezpieczenie, oszczędzać na jedzeniu, a także jak znaleźć wygodne, ale niedrogie noclegi. <br /><br />Pokażę Ci, jak odnaleźć się na lotnisku, nauczysz się, jak robić dokładny research przed wyjazdem i jakie aplikacje ułatwią Ci podróżowanie. Moim celem jest rozwianie wszystkich Twoich wątpliwości i odpowiedzenie na pytania związane z podróżami na własną rękę. Zainwestuj w siebie i swoje podróżnicze marzenia już teraz!",
        "img": "lauragotowska_cover.jpg",
        "profileImg": "lauragotowska_avatar.jpeg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "fromlovetotravel",
        "profileLink": "https://www.instagram.com/fromlovetotravel/",
        "eBookTitle": "Wiedeński Ekspres – Jednodniowa, Ekspresowa Trasa Zwiedzania",
        "pages": 36,
        "regularPrice": 38.5,
        "location": "Wieden",
        "ebookDescription": "Przygotowana przez nas od A do Z ekspresowa trasa zwiedzania Wiednia, a w niej:<br /><br />– Wirtualnie “bierzemy” Cię za rękę i oprowadzamy po Wiedniu<br />– Podpowiadamy, gdzie zacząć i gdzie skończyć, aby nie tracić czasu<br />– Dajemy Ci wskazówki, jak i czym w dane miejsce dojechać/ dojść — z nami na pewno się nie zgubisz!<br />– Podpowiadamy, ile w danym miejscu mniej więcej potrzebujesz czasu<br />– Ciekawie opowiadamy o atrakcjach na trasie, bez zbędnego przynudzania<br />– Zaplanowałyśmy nawet przerwę na lunch oraz pyszną kawkę w taki sposób, aby nie zajęły dużo czasu, a żebyś mógł/ mogła troszkę odpocząć<br />– Dajemy przydatne tipy na trasie, np. polecenia gastronomiczne, fotogeniczne miejsca, info o toaletach, atrakcje dla dzieci oraz różnego rodzaju ciekawostki<br />– Prezentujemy listę polecanych przez nas restauracji na austriacką ucztę, jak również kilka opcji wegańskich<br />– Dajemy Ci również dostęp do naszej kulinarnej mapki Wiednia, na której zaznaczone są wszystkie polecane przez nas wiedeńskie lokale, kawiarnie, bistra itd. <br />– Przedstawiamy propozycje na spędzenie miło wieczoru w Wiedniu",
        "img": "fromlovetotravel_ekspres_cover.jpg",
        "profileImg": "fromlovetotravel_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "fromlovetotravel",
        "profileLink": "https://www.instagram.com/fromlovetotravel/",
        "eBookTitle": "Wiedeński Ekspres - Część Praktyczna",
        "pages": 62,
        "regularPrice": 38.5,
        "location": "Wiedeń",
        "ebookDescription": "W tym e-booku znajdziesz wszystko, co może Ci się przydać podczas planowania podróży do Wiednia oraz pobytu w naszym ukochanym mieście:<br /><br />– Kiedy najlepiej odwiedzić Wiedeń? <br />– Czym dotrzeć do Wiednia? (pociągi, samoloty: dojazd z/na lotnisko, Wiedeń samochodem: parkowanie, winiety);<br />– Gdzie spać? (Polecane noclegi);<br />– Komunikacja miejska w Wiedniu (rodzaje biletów i ceny, taksówki etc.);<br />– Przydatne aplikacje mobilne w Wiedniu;<br />– Jak zaoszczędzić zwiedzając Wiedeń?;<br />– Wydarzenia/imprezy w Wiedniu;<br />– Punkty widokowe;<br />– Kuchnia (Czego spróbować w Wiedniu? Polecane restauracje z austriacką kuchnią) + kulinarna mapka Wiednia ze WSZYSTKIMI restauracjami/ barami/ bistrami, które osobiście polecamy);<br />– Wiedeńskie winnice i “Heurige”;<br />– Wiedeńska Kultura Kawiarniana;<br />– Wiedeński Cmentarz Centralny;<br />– Souveniry (pomysły na pamiątki).",
        "img": "fromlovetotravel_ekspres_praktyczny_cover.jpg",
        "profileImg": "fromlovetotravel_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "fromlovetotravel",
        "profileLink": "https://www.instagram.com/fromlovetotravel/",
        "eBookTitle": "Wiedeń Mniej Znany – 33 miejsca, Których Nie Znajdziesz W Zwykłym Przewodniku",
        "pages": 50,
        "regularPrice": 41,
        "location": "Wiedeń",
        "ebookDescription": "W e-booku “Wiedeń mniej znany” znajdziesz 33 wiedeńskie miejsca poza głównym turystycznym szlakiem. Mnóstwo informacji zarazem historycznych, jak i praktycznych, różne ciekawostki, nasze tipy oraz wskazówki. Przygotowałyśmy również wirtualną mapkę, na której wszystkie 33 miejsca są zaznaczone, dzięki czemu bez problemu je odnajdziesz.<br /><br />Dowiesz się z niego takich ciekawostek, jak m.in. gdzie w Wiedniu:<br />– można zobaczyć najdroższy mebel świata;<br />– znajduje się jedna z najstarszych wiedeńskich restauracji i kto się w niej gościł;<br />– wypić jedną z najlepszych kaw w mieście 😍😍;<br />– znajduje się podziemna toaleta w stylu secesyjnym — wpisana na listę zabytków;<br />– położony jest najstarszy i zarazem bardzo specyficzny wiedeński cmentarz.",
        "img": "fromlovetotravel_33_miejsca_cover.jpg",
        "profileImg": "fromlovetotravel_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "jessinmedellin",
        "profileLink": "https://www.instagram.com/jessinmedellin/",
        "eBookTitle": "Planer Treści - Podróże I Emigracja (Marzec 2024 - Luty 2025)",
        "pages": 39,
        "regularPrice": 99,
        "location": "",
        "ebookDescription": "Content Planner: podróże” został stworzony z myślą o wsparciu Cię w codziennym planowaniu i tworzeniu treści na cały nadchodzący rok. Planer jest idealny dla każdego, kto chce systematycznie dzielić się swoimi doświadczeniami z podróży lub życia za granicą, ale nie ma czasu lub głowy do planowania treści.<br /><br />Znajdziesz w nim pomysły na każdy dzień roku (w sumie 366 unikalnych tematów), które zainspirują Cię do tworzenia angażujących treści. Pamiętaj, że algorytmy platform społecznościowych uwielbiają dużą aktywność i regularność, więc jeśli na samą myśl o tworzeniu angażujących postów czy rolek zaczyna Ci się kręcić w głowie – wykorzystaj moje pomysły.<br /><br />Chciałam stworzyć narzędzie, które uprości Ci planowanie i organizację contentu, pozwalając jednocześnie czerpać radość z podróżowania i życia – bo czas to jedyne, co na tym świecie mamy. Każdy pomysł możesz wykorzystać w dowolny sposób: sprawdzi się jako rolka, zdjęcie z opisem, karuzela, a nawet post na bloga.",
        "img": "jessinmedellin_marzec_luty_cover.jpg",
        "profileImg": "jessinmedellin_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "aniamaluje",
        "profileLink": "https://www.instagram.com/aniamaluje/",
        "eBookTitle": "Jak Podróżować",
        "pages": 209,
        "regularPrice": 109,
        "location": "",
        "ebookDescription": "Z e-booka dowiesz się jak rezerwować hotele i loty, oszczędzać na biletach PKP, mieszkać w bezpiecznej okolicy… krótko mówiąc – skończysz przepłacać za podróże i marnować urlop na nudę.",
        "img": "aniamaluje_cover.jpg",
        "profileImg": "aniamaluje_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "moja_australia_i_swiat",
        "profileLink": "https://www.instagram.com/moja_australia_i_swiat/",
        "eBookTitle": "Droga Do Mojej Australii",
        "pages": 288,
        "regularPrice": 39,
        "location": "Australia",
        "ebookDescription": "E-book na pewno zainteresuje osoby rozważające przeprowadzkę do Australii lub są po prostu ciekawe jak wygląda życie na tym końcu świata.",
        "img": "moja_australia_i_swiat_droga_cover.jpg",
        "profileImg": "moja_australia_i_swiat_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "moja_australia_i_swiat",
        "profileLink": "https://www.instagram.com/moja_australia_i_swiat/",
        "eBookTitle": "W Głąb Mojej Australii",
        "pages": 262,
        "regularPrice": 44,
        "location": "Australia",
        "ebookDescription": "W tym e-booku poruszam tematykę bycia rodzicem w Australii, z dala od rodziny oraz opisuję nasze australijskie przygody z dziećmi, w tym hotelową kwarantannę, którą odbyłam z dwulatkiem.",
        "img": "moja_australia_i_swiat_w_glab_cover.jpg",
        "profileImg": "moja_australia_i_swiat_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "miriam.highhopes",
        "profileLink": "https://www.instagram.com/miriam.highhopes/",
        "eBookTitle": "Poradnik Podróżowania Po Tajlandii 2024",
        "pages": 68,
        "regularPrice": 79,
        "location": "Tajlandia",
        "ebookDescription": "Tajlandię zwiedziłam wzdłuż i wszerz, zebrałam mnóstwo informacji, wskazówek i doświadczeń, którymi w końcu mogę się podzielić i oszczędzić Tobie szukania.🙂 Z miłości do Tajlandii powstał ten oto poradnik podróżowania, który pomoże Ci samodzielnie przygotować się do takiej podróży!<br /><br />Ten e-book jest dla Ciebie jeśli<br /><br />- Ty sam/a nie masz czasu zająć się wyszukiwaniem i weryfikowaniem informacji,<br />- Nie wiesz jak szukać najlepszych lotów i połączeń między wyspami,<br />- Chcesz skorzystać ze sprawdzonych hoteli,<br />- Nie wiesz jak się przygotować na taki wyjazd i co ze sobą zabrać,<br />- Nie wiesz jak się zabrać i od czego zacząć organizację swojej wycieczki,<br />- Chciałbyś/abyś wykorzystać ten czas na maksa i nie tracić go na zbyt długie przemieszczanie się między miejscami,<br />- Chciałbyś/abyś zobaczyć najpiękniejsze miejsca i perełki Tajlandii.",
        "img": "miriam.highhopes_cover.jpg",
        "profileImg": "miriam.highhopes_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "rodzinanomadow",
        "profileLink": "https://www.instagram.com/rodzinanomadow/",
        "eBookTitle": "Wyprawa 5 Mórz",
        "pages": 548,
        "regularPrice": 44.7,
        "location": "",
        "ebookDescription": "Przez 100 dni przejechaliśmy 11 tys. kilometrów, pracowaliśmy zdalnie w 12 miastach Europy, nurkowaliśmy w 5 morzach, a przede wszystkim nazbieraliśmy milion wspomnień: o lesie nenufarów i dzikich koniach żyjących w delcie Dunaju, o tym jak taplaliśmy się w gorących źródłach, o konkursie rzutu kaloszem na festiwalu wina.<br /><br />Zabieramy cię w naszą podróż. Pokażemy ci niesamowite miejsca jakie odkryliśmy, będziemy podpowiadać jak budżetowo zorganizować wymarzone, rodzinne wakacje, opowiemy o tym jak wygląda zdalna praca w podróży, wychowanie dziecka z daleka od domu. A przede wszystkim będziemy razem się śmiać, przeżywać i wzruszać się. Ruszamy?",
        "img": "rodzinanomadow_cover.jpg",
        "profileImg": "rodzinanomadow_avatar.jpg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "rudeiczarne_travelblog",
        "profileLink": "https://www.instagram.com/rudeiczarne_travelblog/",
        "eBookTitle": "Gozo i Comino – Wyspy Spokoju I Błękitu",
        "pages": 201,
        "regularPrice": 69,
        "location": "Gozo i Comino",
        "ebookDescription": "Gozo to druga co do wielkości wyspa archipelagu maltańskiego słynąca ze swojej niepowtarzalnej, spokojnej atmosfery, z pięknej przyrody i uroczych wiosek, w których czas się zatrzymał. Comino zaś to trzecia co do wielkości zamieszkana wyspa Malty, która słynie z niesamowitej błękitnej laguny, ale jest tutaj znacznie więcej do zobaczenia i można znaleźć niezwykle spokojne zakątki.<br /><br />W tym e-booku znajdziesz mnóstwo niezbędnych informacji, wskazówek, ciekawostek, pięknych widoków i wiele więcej, które sprawią, że poznasz te 2 piękne wyspy i bez wątpienia się w nich zakochasz! Ukoisz duszę, zaznasz tej niezwykłej atmosfery, która wycisza, uspokaja, a oczy cieszyć będziesz niesamowitymi widokami.<br /><br />Znajdziesz tutaj dosłownie wszystko, co potrzebujesz przed, ale także w trakcie swojej podróży na Gozo i Comino. Dzięki temu przewodnikowi zorganizujesz swoją własną przygodę dookoła Gozo i Comino i możesz być pewien, że będzie to przygoda niezapomniana!",
        "img": "rudeiczarne_travelblog_cover.jpg",
        "profileImg": "rudeiczarne_travelblog_avatar.png",
        "rank": 7,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "zielonyplecak",
        "profileLink": "https://www.instagram.com/zielonyplecak",
        "eBookTitle": "Jak Zorganizować Podróż Do Kolumbii?",
        "pages": 147,
        "regularPrice": 128,
        "location": "Kolumbia",
        "ebookDescription": "Kompleksowy przewodnik i poradnik po Kolumbii:<br /><br /> Wszystko, co musisz wiedzieć przed i podczas podróży do Kolumbii<br />Gotowe trasy na 5 tygodni zwiedzania Kolumbii<br />Praktyczne informacje, jak ogarnąć wielkie miasta i małe miasteczka.",
        "img": "zielonyplecak_cover.jpg",
        "profileImg": "zielonyplecak_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "story_of_my_trip",
        "profileLink": "https://www.instagram.com/story_of_my_trip_/",
        "eBookTitle": "Sprytne Podróżowanie",
        "pages": 112,
        "regularPrice": 87,
        "location": "",
        "ebookDescription": "Podróżuj tanio i sprytnie! Odkryj tajniki budżetowych wypraw, korzystając z lat mojego doświadczenia w przemierzaniu świata. Dzięki tej lekturze, zarówno początkujący, jak i doświadczeni podróżnicy odkryją, jak znaleźć najkorzystniejsze oferty lotów, noclegów i wiele więcej. Wyrusz w niezapomnianą przygodę bez nadwyrężania portfela! Ten interaktywny e-book zwróci Ci się już kilkakrotnie podczas pierwszej podróży! Jeżeli nie zaoszczędzisz, przy pierwszym wyjeździe, dzięki temu poradnikowi, oddam Ci pieniądze!",
        "img": "story_of_my_trip_cover.jpg",
        "profileImg": "story_of_my_trip_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "gazelawlaponi",
        "profileLink": "https://www.instagram.com/gazelawlaponii/",
        "eBookTitle": "Przewodnik Po Lofotach",
        "pages": 90,
        "regularPrice": 127,
        "location": "Lofoty",
        "ebookDescription": "Przewodnik po Lofotach: Oszczędź czas i pieniądze!<br /><br />E-book dla miłośników północy, zapewniający praktyczne informacje, porady dotyczące transportu, opisy miejsc i ciekawostki. Skierowany do tych, którzy cenią swój czas i pieniądze, gwarantując dobrze zaplanowane wakacje bez stresu.<br /><br />Dla kogo jest przewodnik?<br /><br />Dedykowany miłośnikom arktycznej natury, oferuje informacje dostosowane do różnych por roku i zainteresowań podróżnych, po 10 latach eksploracji.<br /><br />Co zyskujesz?<br /><br />Pewność, że nic Cię nie ominie, uwzględniając noclegi, atrakcje, ciekawostki i trekkingi. To unikalne źródło informacji o Lofotach, skondensowana wiedza oparta na pasji do norweskiej przyrody, dostępna tylko w formie e-booka.",
        "img": "gazelawlaponi_cover.jpg",
        "profileImg": "gazelawlaponi_avatar.jpg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "aleksandra_helail",
        "profileLink": "https://www.instagram.com/aleksandra_helail/",
        "eBookTitle": "Kobiety Egiptu",
        "pages": 225,
        "regularPrice": 79.9,
        "location": "Egipt",
        "ebookDescription": "Książka \"Kobiety Egiptu\" to rezultat rozmów z dwunastoma Egipcjankami, przedstawiającymi różnorodne perspektywy życiowe. Oto krótkie opisy kilku z nich:<br /><br />- Khadiga na co dzień nosi nikab – całkowicie zakrywa twarz, widać jej tylko oczy,<br />- Mąż Karimy ma trzy żony,<br />- Donya jest agnostyczką i dystansuje się od wielu aspektów egipskiej kultury,<br />- Nada ma 70 lat i w młodym wieku wyjechała za granicę, by robić karierę. Na stare lata wróciła do Egiptu,<br />- Mary i Sara są chrześcijankami,<br />- Farida pracuje w międzynarodowej organizacji zajmującej się rozwojem świadomości na temat antykoncepcji w Egipcie.<br /><br />Wszystkie kobiety odpowiedziały na podobne pytania dotyczące ubioru, pracy, małżeństwa i macierzyństwa, ukazując różnorodność społeczeństwa egipskiego. Książka ma na celu przedstawienie autentycznego obrazu zróżnicowanego Egiptu, wykraczając poza turystyczne perspektywy i obalając stereotypy.",
        "img": "aleksandra_helail_cover.jpg",
        "profileImg": "aleksandra_helail_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "matkawariatkapl",
        "profileLink": "https://www.instagram.com/matkawariatkapl/",
        "eBookTitle": "Atrakcje Dla Dzieci I Ciekawe Miejsca W Województwie Zachodniopomorskim",
        "pages": 66,
        "regularPrice": 35,
        "location": "Polska",
        "ebookDescription": "Ponad 230 atrakcji i ciekawych miejsc w województwie zachodniopomorskim. Zaskoczenie gwarantowane!<br /><br />E-book nie jest przewodnikiem, a raczej ściągą, dzięki której można szybko znaleźć atrakcje i ciekawe miejsca w danej okolicy, aby jak najlepiej zaplanować czas. Znajdziesz w nim wyselekcjonowane, najciekawsze według mnie atrakcje znajdujące się w regionie, w którym mieszkasz lub do którego się wybierasz. Przeszukałam tysiące (serio!) stron internetowych, aby wybrać te najbardziej interesujące i niczego nie pominąć.<br /><br />Na początku ebooka znajdziesz atrakcje znajdujące się na północy, a na końcu na południu województwa. Ułożone zostały od zachodu, w stronę wschodu. Ponadto każdy z e-booków zawiera klikalny indeks nazw wszystkich miejscowości. Po kliknięciu w nazwę miejscowości, zostaniesz przeniesiony na stronę, na której poznasz atrakcje, które się w niej znajdują.",
        "img": "matkawariatkapl_zachodniopom_cover.jpg",
        "profileImg": "matkawariatkapl_avatar.jpg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "matkawariatkapl",
        "profileLink": "https://www.instagram.com/matkawariatkapl/",
        "eBookTitle": "Atrakcje Dla Dzieci I Ciekawe Miejsca W Województwie Lubuskim",
        "pages": 38,
        "regularPrice": 35,
        "location": "Polska",
        "ebookDescription": "Ponad 110 atrakcji dla dzieci i ciekawych miejsc w województwie lubuskim. Zaskoczenie gwarantowane!<br /><br />E-book nie jest przewodnikiem, a raczej ściągą, dzięki której można szybko znaleźć atrakcje i ciekawe miejsca w danej okolicy, aby jak najlepiej zaplanować czas. Znajdziesz w nim wyselekcjonowane, najciekawsze według mnie atrakcje znajdujące się w regionie, w którym mieszkasz lub do którego się wybierasz. Przeszukałam tysiące (serio!) stron internetowych, aby wybrać te najbardziej interesujące i niczego nie pominąć.<br /><br />Na początku ebooka znajdziesz atrakcje znajdujące się na północy, a na końcu na południu województwa. Ułożone zostały od zachodu, w stronę wschodu. Ponadto każdy z e-booków zawiera klikalny indeks nazw wszystkich miejscowości. Po kliknięciu w nazwę miejscowości, zostaniesz przeniesiony na stronę, na której poznasz atrakcje, które się w niej znajdują.",
        "img": "matkawariatkapl_lubskie_cover.jpg",
        "profileImg": "matkawariatkapl_avatar.jpg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "matkawariatkapl",
        "profileLink": "https://www.instagram.com/matkawariatkapl/",
        "eBookTitle": "Atrakcje Dla Dzieci I Ciekawe Miejsca W Województwie Dolnośląskim",
        "pages": 96,
        "regularPrice": 35,
        "location": "Polska",
        "ebookDescription": "Około 400 atrakcji i ciekawych miejsc w województwie dolnośląskim. Zaskoczenie gwarantowane!<br /><br />E-book nie jest przewodnikiem, a raczej ściągą, dzięki której można szybko znaleźć atrakcje i ciekawe miejsca w danej okolicy, aby jak najlepiej zaplanować czas. Znajdziesz w nim wyselekcjonowane, najciekawsze według mnie atrakcje znajdujące się w regionie, w którym mieszkasz lub do którego się wybierasz. Przeszukałam tysiące (serio!) stron internetowych, aby wybrać te najbardziej interesujące i niczego nie pominąć.<br /><br />Na początku ebooka znajdziesz atrakcje znajdujące się na północy, a na końcu na południu województwa. Ułożone zostały od zachodu, w stronę wschodu. Ponadto każdy z ebooków zawiera klikalny indeks nazw wszystkich miejscowości. Po kliknięciu w nazwę miejscowości, zostaniesz przeniesiony na stronę, na której poznasz atrakcje, które się w niej znajdują.",
        "img": "matkawariatkapl_dolno_slaskie_cover.jpg",
        "profileImg": "matkawariatkapl_avatar.jpg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "matkawariatkapl",
        "profileLink": "https://www.instagram.com/matkawariatkapl/",
        "eBookTitle": "Atrakcje Dla Dzieci I Ciekawe Miejsca W Województwie Wielkopolskim",
        "pages": 70,
        "regularPrice": 35,
        "location": "Polska",
        "ebookDescription": "Ponad 250 atrakcji i ciekawych miejsc w województwie wielkopolskim. Zaskoczenie gwarantowane!<br /><br />Ebook nie jest przewodnikiem, a raczej ściągą, dzięki której można szybko znaleźć atrakcje i ciekawe miejsca w danej okolicy, aby jak najlepiej zaplanować czas. Znajdziesz w nim wyselekcjonowane, najciekawsze według mnie atrakcje znajdujące się w regionie, w którym mieszkasz lub do którego się wybierasz. Przeszukałam tysiące (serio!) stron internetowych, aby wybrać te najbardziej interesujące i niczego nie pominąć.<br /><br />Na początku ebooka znajdziesz atrakcje znajdujące się na północy, a na końcu na południu województwa. Ułożone zostały od zachodu, w stronę wschodu. Ponadto każdy z ebooków zawiera klikalny indeks nazw wszystkich miejscowości. Po kliknięciu w nazwę miejscowości, zostaniesz przeniesiony na stronę, na której poznasz atrakcje, które się w niej znajdują.",
        "img": "matkawariatkapl_wielkopolskie_cover.jpg",
        "profileImg": "matkawariatkapl_avatar.jpg",
        "rank": 9,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "wszedobylscy",
        "profileLink": "https://www.instagram.com/wszedobylscy/",
        "eBookTitle": "Pomorskie - 55 Pomysłów Na Rodzinne Podróże",
        "pages": 162,
        "regularPrice": 39.99,
        "location": "Polska",
        "ebookDescription": "Zaplanuj z nami ciekawą podróż przez województwo pomorskie! W przewodniku znajdziesz aż 55 pomysłów na rodzinne spędzanie czasu w województwie pomorskim. Miejsca, które Wam polecamy są nam dobrze znane, a niektóre z nich odwiedzaliśmy wielokrotnie. Z naszym interaktywnym przewodnikiem  będziecie mogli zaplanować wycieczki na kilka godzin, jedeń dzień czy na weekend i dłużej.",
        "img": "wszedobylscy_cover.jpg",
        "profileImg": "wszedobylscy_avarar.jpg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "ann.sadowska",
        "profileLink": "https://www.instagram.com/ann.sadowska/",
        "eBookTitle": "Czarnogóra – Przewodnik Praktyczny",
        "pages": 117,
        "regularPrice": 59,
        "location": "Czarnogóra",
        "ebookDescription": "To praktyczny przewodnik dla wszystkich, którzy chcą efektywnie wykorzystać czas w Czarnogórze i odkryć jej piękno! <br />Co znajdziesz w e-booku:<br /><br />- praktyczne informacje o Czarnogórze<br />- trzy gotowe plany wycieczek po Czarnogórze (na 3 dni, na 5 dni i na 7 dni) – w wersji z autem i bez<br />- interaktywne mapy z zaznaczonymi trasami,  polecanymi atrakcjami i restauracjami<br />- miejsca, które warto zobaczyć w Czarnogórze z praktycznym opisem.",
        "img": "ann.sadowska_cover.jpg",
        "profileImg": "ann.sadowska_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "dietaniecud",
        "profileLink": "https://www.instagram.com/dietaniecud/",
        "eBookTitle": "Azja To Cud",
        "pages": 102,
        "regularPrice": 79.9,
        "location": "",
        "ebookDescription": "W e-booku znajdziesz:<br /><br />- 35 przepisów na pyszne azjatyckie dania z łatwo dostępnych składników.<br />- Połączenia smaków w przystawkach, sałatkach, zupach, i daniach głównych, wraz z przewodnikiem po orientalnych przyprawach.<br />- Rozwiązania dla osób na diecie roślinnej dzięki recepturze na pyszne „mięso” z tofu.<br />- Wartości odżywcze dla każdego posiłku, łatwo wpleciesz je w swoją codzienną dietę.<br /><br />Poznasz moje ukochane przepisy na dania kuchni azjatyckiej. Proste metody gotowania prosto z podróży po Azji. Udowodnię, że kuchnia azjatycka nie jest trudna. Nauczę Cię gotować z krótką listą składników.  Zaoszczędzisz pieniądze i… kalorie w porównaniu do dań dostępnych w restauracjach.",
        "img": "dietaniecud_cover.jpg",
        "profileImg": "dietaniecud_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "wieczniewolni",
        "profileLink": "https://www.instagram.com/wieczniewolni/",
        "eBookTitle": "Jak Zorganizować Wymarzoną Podróż",
        "pages": 192,
        "regularPrice": 79.99,
        "location": "",
        "ebookDescription": "Ten poradnik jest idealny dla osób, które:<br /><br />- Chcą zorganizować swoją wymarzoną podróż,<br />- Chcą wyruszyć w świat bez biura podróży,<br />- Marzą o podróżach ale nie wiedzą jak się za to zabrać,<br />- Chcą mieć pełną swobodę i kontrolę nad przebiegiem swojej podróży,<br />- Są doświadczonymi podróżnikami, jak i dla tych, którzy dopiero zaczynają swoją przygodę z podróżami,<br />- Mają dość wycieczek zorganizowanych przez biura podróży,<br />- Chcą zaoszczędzić pieniądze, unikając najczęściej popełnianych błędów,<br />- Chcą czerpać z podróży jak najwięcej,<br />- Chcą ruszyć poza \"utarty szlak\",<br />- Oczekują od podróży \"więcej\".",
        "img": "wieczniewolni_cover.jpg",
        "profileImg": "wieczniewolni_avatar.jpg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "slowspotter",
        "profileLink": "https://www.instagram.com/slowspotter/",
        "eBookTitle": "Hiszpania Kamperem - Edycja - Andaluzja",
        "pages": 164,
        "regularPrice": 89.9,
        "location": "Andaluzja",
        "ebookDescription": "W tym e-booku znajdziecie porady praktyczne odnośnie przygotowania do podróży kamperem, zasad panujących w Hiszpanii oraz przewodnik po Andaluzji, w którym dzielimy się konkretnymi miejscami odkrytymi w trakcie naszych podróży. Polecamy go dla niespiesznych podróżników, którzy rozpoczynają swoją przygodę z kamperem i marzą o road tripie po słonecznej Andaluzji.",
        "img": "slowspotter_cover.jpg",
        "profileImg": "slowspotter_avatar.jpeg",
        "rank": 10,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "rodzinawswiat",
        "profileLink": "https://www.instagram.com/rodzinawswiat/",
        "eBookTitle": "Japonia. Subiektywny Przewodnik Nieokrzesanego Gaijina Po Meandrach Zaskakującej Rzeczywistości",
        "pages": 368,
        "regularPrice": 31.5,
        "location": "Japonia",
        "ebookDescription": "Jak się nie zbłaźnić w restauracji i dlaczego jest to niemożliwe? Czy Polacy również Japończyków nauczyli jeść widelcem?  Jak się pracuje w Japonii? Dlaczego Japonki mają fajnie? Gdzie jest ojciec? Co mają trzęsienia ziemi do wyposażenia mieszkania? Szintoizm: religia idealna?<br /><br />To nie jest sztywne studium o Japonii. To pełne humoru historie z życia wzięte, które w lekki sposób przybliżają kulturę tego niezwykle interesującego kraju.<br /><br />Mamy nadzieję, że choć w niewielkim stopniu przekażemy Wam naszą sympatię do Japonii. Oraz chęć odwiedzenia tego niezwykłego kraju. I mówimy to my – ludzie, którzy do 2018 roku nawet nie brali jej pod uwagę jako kierunku wyjazdowego. Bo co tam właściwie może być ciekawego? Ignoranci! Wprawdzie nikłe są szanse na doświadczenie dokładnie tego, z czym się zetknęliśmy, ale w końcu nigdy nic nie wiadomo, bo życie pisze różne scenariusze, o czym przekonaliśmy się ze zdwojoną, japońską siłą. I tego się trzymajmy. A tymczasem, sayonara. Ewentualnie, gudu baju.",
        "img": "rodzinawswiat_cover.jpg",
        "profileImg": "rodzinawswiat_avatar.jpg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "couple.away",
        "profileLink": "https://www.instagram.com/couple.away/",
        "eBookTitle": "Teneryfa - Jedyny Taki Przewodnik",
        "pages": 468,
        "regularPrice": 119,
        "location": "Teneryfa",
        "ebookDescription": "Interaktywny i kompletny przewodnik po Teneryfie w formie e-booka. <br /><br />Co znajdziesz w środku?<br /><br />– Informacje praktyczne<br />- 49 polecanych plaż i basenów naturalnych<br />– Blisko 50 sprawdzonych szlaków wraz z mapami i wszystkimi informacjami<br />– 60 przetestowanych restauracji i kawiarnii<br />– Ponad 60 punktów widokowych<br />– Rozdział o podróżowaniu z dzieckiem po wyspie<br />– Vanlife na Teneryfie<br />– Gotowe plany podróży na 4, 7, 14 dni<br />- wiele, wiele więcej...",
        "img": "couple.away_teneryfa_cover.jpg",
        "profileImg": "couple.away_avatar.jpeg",
        "rank": 2,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "gdziestybyl",
        "profileLink": "https://www.instagram.com/gdziestybyl/",
        "eBookTitle": "Jak Podróżować, Nie Rezygnując Z Pracy",
        "pages": 222,
        "regularPrice": 119,
        "location": "",
        "ebookDescription": "Istnieje przekonanie, że jeśli pracujesz, zwłaszcza na etacie, ogranicza Cię to w podróżowaniu. Czy to prawda? <br /><br />W jakiejś mierze na pewno, ale ten e-book ma przekonać Cię do podróżowania więcej i pokazać, jak to robić bez rzucania pracy (bo podróżować dużo się da, nawet gdy ma się pracę). Skierowany on jest jednak nie tylko do osób czynnych zawodowo, czy pracujących w korporacjach, ale ogólnie do wszystkich chcących po prostu więcej podróżować. Mam nadzieję, że z zawartych tu wskazówek skorzystają też osoby rozpoczynające swoją karierę, planujące przebranżowienie się, poszukujące pracy, w której można podróżować więcej, czy nawet przyszli studenci.",
        "img": "gdziestybyl_cover.jpg",
        "profileImg": "gdziestybyl_avatar.jpeg",
        "rank": 2,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "wedrownemotyle",
        "profileLink": "https://www.instagram.com/wedrownemotyle/",
        "eBookTitle": "Podróżuj Bliżej",
        "pages": 305,
        "regularPrice": 59.9,
        "location": "Polska",
        "ebookDescription": "Marzysz o powrocie do podróżowania? Już, teraz? Chcesz wykorzystać każdą okazję w tym roku, by podziwiać sielankowe krajobrazy, włóczyć się po lesie i wdychać świeże powietrze, a przy tym uniknąć tłumów? Możesz zrobić to w Polsce! Sięgnij po „Podróżuj bliżej” i udaj się wraz z nami na 21 wycieczek po Polsce mniej znanej, wciągającej, fascynującej i nieco tajemniczej. To książka o Polsce inna niż wszystkie. Nauczymy się uważności, pokażemy jak cieszyć się podróżą po kraju i ciągle nim miło zaskakiwać. Oto sprawdzone propozycje na weekendy, urlop i wakacje w regionach mniej znanych – spraw, by były w tym roku niezapomniane!<br/><br/>Co znajdziesz w książce „Podróżuj bliżej”?<br/>-21 opisanych regionów na urlop, aż 193 miejsca<br/>-Ręcznie malowane mapy z oznaczonymi atrakcjami<br/>-Polecane miejsca noclegowe i restauracje<br/>-Mapy Compass gotowe do użycia w podroży<br/>-Ponad 200 kolorowych zdjęć!",
        "img": "wedrownemotyle_cover.jpg",
        "profileImg": "wedrownemotyle_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "whatannawears",
        "profileLink": "https://www.instagram.com/whatannawears/",
        "eBookTitle": "Bali Na Twoim Talerzu",
        "pages": 105,
        "regularPrice": 79.99,
        "location": "Bali",
        "ebookDescription": "E-booka “Bali na Twoim talerzu” napisałam podczas mojego półrocznego pobytu na Bali. Jest on wynikiem mojej miłości do tego miejsca, jego kultury i kuchni. Mam nadzieję, że dzięki niemu otworzę Wasze zmysły na nowe, nieoczywiste połączenia i zaskoczę Was nowymi połączeniami dość oczywistych składników. <br/><br/>47 bezmięsnych przepisów dla całej rodziny, słodkich i słonych, na mały i duży głód. Oprócz części “kulinarnej” znajdziecie w nim ekstrakt “Gotowanie&Mindfullness” oraz kilka myśli o mojej przemianie.",
        "img": "whatannawears_cover.jpg",
        "profileImg": "whatannawears_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "ahoj.przygodo.ahoj",
        "profileLink": "https://www.instagram.com/ahoj.przygodo.ahoj",
        "eBookTitle": "AHOJ! WROCŁAW – Paszport Młodego Odkrywcy",
        "pages": 98,
        "regularPrice": 29.9,
        "location": "Polska",
        "ebookDescription": "Brak pomysłu na zwiedzanie Wrocławia z dziećmi? Koniecznie sięgnij po AHOJ! WROCŁAW – Paszport Młodego Odkrywcy. Nasza pierwsza książka publikacja, która okazała się HITEM!\n\nProjekt stworzyliśmy z myślą o rodzinnych spacerach. Przewodnik zachęca do wyjścia poza własne podwórko i zorganizowania mikrowyprawy w nieznaną dzielnicę miasta.\n\n45 miejsc do odkrycia, ciekawostki, zadania i zabawy, które urozmaicą spacer najmłodszym.",
        "img": "ahoj.przygodo.ahoj_wroclaw_cover.jpg",
        "profileImg": "ahoj.przygodo.ahoj_avatar.jpg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "ahoj.przygodo.ahoj",
        "profileLink": "https://www.instagram.com/ahoj.przygodo.ahoj",
        "eBookTitle": "AHOJ! DOLNY ŚLĄSK – Paszport Młodego Odkrywcy",
        "pages": 86,
        "regularPrice": 29.9,
        "location": "Polska",
        "ebookDescription": "Kopalnia pomysłów na zwiedzanie Dolnego Śląska z dziećmi! Aż 50 miejsc podzielonych na 7 kategorii. Przeżyjesz prawdziwą przygodę i przekonasz się, że Dolny Śląsk to miejsce, które można odkrywać nieustannie!\n<br/><br/>\nPublikacja wydana jest na twardym papierze, zawiera aż 120 strony pełne inspiracji, aktywności oraz praktycznych informacji. Jest to idealna propozycja dla rodzin z dziećmi odkrywającymi Dolny Śląsk!",
        "img": "ahoj.przygodo.ahoj_dolnyslask_cover.jpg",
        "profileImg": "ahoj.przygodo.ahoj_avatar.jpg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "adventures.after.work",
        "profileLink": "https://www.instagram.com/adventures.after.work/",
        "eBookTitle": "Przewodnik Po Patagonii. Jak samodzielnie Zorganizować Wyjazd",
        "pages": 125,
        "regularPrice": 74.99,
        "location": "Patagonia",
        "ebookDescription": "Praktyczny przewodnik o tym, jak samodzielnie zorganizować wyprawę do Patagonii.<br/><br/>Przeprowadzi Cię przez wszystkie formalności przed wylotem, powie jak przygotować się do wyprawy od A do Z, wskaże najciekawsze miejsca, opowie o kulturze oraz lokalnych przysmakach. Przewodnik zawiera również gotowe <strong>plany podróży na 7, 14 oraz 21 dni</strong>.<br/><br/>Znajdziesz w nim polecane przez nas hotele, wypożyczalnie samochodów, restauracje oraz linki do rezerwacji atrakcji. To skondensowana wiedza, która znacząco pomoże Ci skrócić czas przygotowania do wyjazdu.",
        "img": "adventures.after.work_patagonia_cover.jpg",
        "profileImg": "adventures.after.work_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "annwithswag",
        "profileLink": "https://www.instagram.com/annwithswag/",
        "eBookTitle": "Lizbona Okiem Lokalsa",
        "pages": 51,
        "regularPrice": 59,
        "location": "Lizbona",
        "ebookDescription": "W moim e-booku pragnę podzielić się z Tobą niezapomnianymi doświadczeniami gastronomicznymi. W tym przewodniku znajdziesz miejsca, gdzie zanurzysz się w autentyczną atmosferę tego miasta jak lokals! Niech ten e-book będzie Twoim\nosobistym przewodnikiem, który będzie towarzyszył Ci podczas pierwszej wizyty lub\njuż przy kolejnym odkrywaniu Lizbony.\n\n- Z tym e-bookiem odwiedzisz kawiarnie jedyną w swoim rodzaju i poczujesz, jakby zatrzymał się czas dawnej Portugalii - lata 70', wypijesz przepyszne espresso, które kosztuje jedynie 50 centów! \n\n- Doświadczysz Portugalskiego \"dania dnia\" na które składa się przystawka, danie główne, deser, kawa i wino lub piwo, a to wszystko za mniej niż 10 EUR!\n\n- Odkryjesz ukryta za bardzo wąskimi drzwiami piekarnie, która jest czynna tylko w nocy od godziny 21:00 aż do 3:00 rano. Moje ulubione miejsce, by spróbować autentycznych portugalskich wypieków!",
        "img": "annwithswag_cover.jpg",
        "profileImg": "annwithswag_avatar.jpg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "gdanskieopowiesci",
        "profileLink": "https://www.instagram.com/gdanskieopowiesci/",
        "eBookTitle": "Gdańsk Ukryty Na Naszych Oczach",
        "pages": 48,
        "regularPrice": 36.22,
        "location": "Gdańsk",
        "ebookDescription": "Krótki przewodnik po nieoczywiście oczywistych miejscach w Gdańsku. Gdańsk ze swoją ponad tysiącletnią historią jest wciąż miastem żywym, gdzie nigdy nie cichnie stukot butów na ulicy. W codziennym jego życiu gubi się jednak historia i miejskie obyczaje.<br /><br />Mimo tego, że centrum miasta jest najchętniej odwiedzane, wydawać może się, że zwiedzone już przez wszystkich wzdłuż i wszerz, ciągle kryje parę tajemnic. Nie mówię tutaj jednak o tajemnicach zaległych w starych piwnicach czy strzeżonych posesjach prywatnych, a o tajemnicach ukrytych na naszych oczach właśnie.<br /><br />W tym e-booku uchyliłem rąbka tajemnicy 9 miejsc w praktycznie ścisłym centrum miasta. Są to miejsce dobrze znane z widoku. Nie znajdziemy przy nich żadnych tabliczek, które nawet wskazywałyby na jakąkolwiek relewancję historyczną danego miejsca. Pozwoliłem sobie też przypomnieć pewien zapomniany rytuał, święty we wszystkich przyportowych knajpach i praktykowany przez wszystkie nadmotławskie szelmy.",
        "img": "gdanskieopowiesci_cover.jpg",
        "profileImg": "gdanskieopowiesci_avatar.jpg",
        "rank": 10,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "italia_e_cucina",
        "profileLink": "https://www.instagram.com/italia_e_cucina/",
        "eBookTitle": "Favorite! Kulinarna Eskapada Po Neapolu",
        "pages": 125,
        "regularPrice": 39,
        "location": "Neapol",
        "ebookDescription": "Favorite! Kulinarna eskapada po Neapolu to mój pierwszy e-book o kuchni Neapolu stworzony razem Kasią Cehanczuk, która od 30 lat mieszka niedaleko Neapolu i do perfekcji opanowała lokalną kuchnię. Zresztą jest już prawie profesjonalną kucharką, bo właśnie kończy odpowiedni kurs. Ja z kolei od lat fascynuję się historią włoskich kulinariów, a moje liczne podróże to przede wszystkim odkrywanie lokalnych smaków. Dlatego w tym e-booku opowiadam Wam różne historyjki i anegdotki o daniach, na które Kasia podaje przepisy. Znajdziesz też różne ciekawostki na temat tego, czego warto spróbować w Neapolu (i gdzie!).",
        "img": "italia_e_cucina_cover.jpg",
        "profileImg": "italia_e_cucina_avatar.jpg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "jaknatureckimkazaniu",
        "profileLink": "https://www.instagram.com/jaknatureckimkazaniu/",
        "eBookTitle": "Stambuł Na Własną Rękę. Alternatywny Przewodnik",
        "pages": 102,
        "regularPrice": 79,
        "location": "Stambuł",
        "ebookDescription": "Co zawiera e-book:<br />- opisy moich ulubionych dzielnic wraz z ciekawostkami i anegdotami,<br />- przydatne wskazówki pomagające w odnalezieniu się w mieście np. jak kupić kartę miejską, jak dotrzeć z lotniska do centralnej części miasta, jak kupić kartę sim i wiele innych,<br />- polecane pamiątki i wyjątkowe produkty, które warto kupić w Turcji,<br />- polecane hotele (wybrane z pomocą mojej społeczności)<br />- gotowe plany zwiedzania na 3 i 5 dni,<br />- polecane restauracje i kawiarnie z autentycznym tureckim jedzeniem<br /><br />Dla kogo jest e-book:<br />- dla planujących podróż do Stambułu,<br />- dla osób, które już w Stambule były, ale chcą odkrywać nowe miejsca,<br />- dla miłośników Turcji i Stambułu.",
        "img": "jaknatureckimkazaniu_cover.jpg",
        "profileImg": "jaknatureckimkazaniu_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "cleo.rozwadowska",
        "profileLink": "https://www.instagram.com/cleo.rozwadowska/",
        "eBookTitle": "Naszymi Śladami Dookoła Świata",
        "pages": 181,
        "regularPrice": 70,
        "location": "",
        "ebookDescription": "Jest to to pierwsza część z serii “Naszym śladami dookoła świata”, która opowiada historię o Ameryce Południowej i Północnej.<br /><br />To interaktywny eBook w którym głównie Owen przeprowadzi Was przez naszą podróż krok po kroku w oparciu na swojej merytorycznej wiedzy i faktach. Prawie 200 stron praktycznej wiedzy i inspiracji.",
        "img": "cleo.rozwadowska_cover.jpg",
        "profileImg": "cleo.rozwadowska_avatar.jpg",
        "rank": 7,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "na_zachodzie_sri_lanki",
        "profileLink": "https://www.instagram.com/na_zachodzie_sri_lanki/",
        "eBookTitle": "Sri Lanka - Garść przydatnych informacji związanych z podróżą na wyspę",
        "pages": 102,
        "regularPrice": 39.99,
        "location": "Sri Lanka",
        "ebookDescription": "Planujesz podróż na Sri Lankę? Czego warto być świadomym? Na co się przygotować? Kiedy lecieć? Jak wyglądają kwestie płatności, transportu, bezpieczeństwa? Co spakować i co załatwić przed przylotem? <br /><br />Oto garść uniwersalnych, praktycznych informacji dotyczących wyjazdu i pobytu na wyspie. Poparte wieloletnim doświadczeniem, aktualne i w przystępnej formie. Przyjemnej lektury i niezapomnianych wrażeń ze Sri Lanki!",
        "img": "na_zachodzie_sri_lanki_cover.jpg",
        "profileImg": "na_zachodzie_sri_lanki_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "kasia_i_swiat",
        "profileLink": "https://www.instagram.com/kasia_i_swiat",
        "eBookTitle": "Cztery Twarze Meksyku",
        "pages": 180,
        "regularPrice": 90,
        "location": "Meksyk",
        "ebookDescription": "Ten e-book jest dla Ciebie, jeśli:<br /><br />- Planujesz wyprawę do Meksyku na własną rękę, ale masz obawy przed wyruszeniem do tak odległego i odmiennego kraju;<br />- Pragniesz zobaczyć jak najwięcej, ale dysponujesz niewielką ilością czasu;<br />- Lubisz różnorodność i bogactwo doświadczeń w jednej podróży;<br />- Interesuje Cię komfortowa podróż, obejmująca loty wewnętrzne oraz krótkie przejazdy samochodem;<br />- Chcesz zobaczyć najważniejsze starożytne budowle, poczuć klimat kolonialnych miast, a przede wszystkim doświadczyć kontaktu z dziką naturą oraz znaleźć czas na plażowanie i „leniuchowanie”;<br />- Pragniesz ominąć tłumy turystów i poczuć prawdziwy klimat Meksyku.",
        "img": "kasia_i_swiat_cover.jpg",
        "profileImg": "kasia_i_swiat_avatar.jpeg",
        "rank": 10,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "podakropolem",
        "profileLink": "https://www.instagram.com/Podakropolem",
        "eBookTitle": "City Break W Atenach",
        "pages": 227,
        "regularPrice": 59,
        "location": "Ateny",
        "ebookDescription": "W przewodniku znajdziesz wszystko, co każdy przylatujący do Aten turysta powinien wiedzieć - praktyczne informacje i gotowe rozwiązania do samodzielnej organizacji pobytu.<br /><br />• 227 stron informacji i wiele linków do stron internetowych i map.<br /><br />• Ponad 100 miejsc i atrakcji do odkrycia.<br /><br />• Dania kuchni greckiej i najlepsze miejsca na obiad czy kolację.<br /><br />• Praktyczne informacje na temat zwiedzania i dokładne wskazówki na temat poruszania się po mieście.<br /><br />• Podpowiedzi, gdzie zrobisz najładniejsze zdjęcia.<br /><br />• Spis festiwali, świąt, nietypowych wydarzeń, które mają miejsce w Atenach.",
        "img": "podakropolem_cover.jpg",
        "profileImg": "podakropolem_avatar.jpg",
        "rank": 9,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "archi.trav",
        "profileLink": "https://www.instagram.com/archi.trav/",
        "eBookTitle": "Przewodnik Po Pradze. Spacery Po Najpiękniejszych Zakątkach",
        "pages": 238,
        "regularPrice": 60,
        "location": "Praga",
        "ebookDescription": "Przewodnik ten to przede wszystkim jedyne w swoim rodzaju spojrzenie na stolicę Czech – oczami pasjonatki. Jedną z głównych idei przyświecających autorce była chęć stworzenia lektury soczystej, opatrzonej pięknymi zdjęciami i wciągającej czytelnika niczym wir w wyjątkowy klimat Pragi.<br /><br />Na 238 stronach znajdziesz osiem tras prowadzących po centrum miasta, wzbogaconych o mnóstwo wartych zobaczenia miejsc, znajdujących się o przysłowiowy rzut beretem od głównych atrakcji. Podążając wytyczonymi przeze mnie ścieżkami odkryjesz m.in.:<br /><br />- najmniejszą rzeźbę Davida Černego w Pradze (i kilka innych),<br />-  najpiękniejsze barokowe ogrody w Europie,<br />-  zaułki romantycznej Malej Strany,<br />-  tajemniczą instalację z książek,<br />- najstarszą karuzelę w Europie,<br />-  miejsca, z których rozpościerają się najpiękniejsze panoramy na miasto,<br />-  olśniewające detale na kamienicach…<br />i wiele innych wspaniałych miejsc!",
        "img": "archi.trav_cover.jpg",
        "profileImg": "archi.trav_avatar.jpeg",
        "rank": 6,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "pelnapara",
        "profileLink": "https://www.instagram.com/pelnapara/",
        "eBookTitle": "(Nie)poradnik - Podróże Z Niemowlakiem I Małymi Dziećmi Część 1",
        "pages": 194,
        "regularPrice": 45.9,
        "location": "",
        "ebookDescription": "W tej części (nie)poradnika dowiecie się, jak opuścić bezpieczną przystań i wyruszyć w świat z bombelkiem. Dowiecie się, kiedy jest dobry moment na pierwszą podróż. Czym się różnią wakacje z 6-cio, 8-mio, czy 12-mięsiecznym bobasem, od tych ze starszymi dziećmi oraz jak wyglądają podróże z high need baby. Przeprowadzę Was krok po kroku przez proces planowania podróży, zarówno tych na własną rękę, jak i z biurem podróży. Znajdziecie tu mnóstwo hotelowych polecajek na rodzinne wczasy w Polsce i na świecie.<br /><br />A jak już zaplanujecie podróż, to pokażę Wam, jak się do niej przygotować. Od wyrobienia dokumentów, poprzez wybór ubezpieczenia, zakup idealnego wózka, pakowanie i kompletowanie apteczki. A na deser sprawdzone zabawki, które pomogą Wam przetrwać podróż.",
        "img": "pelnapara_nieporadnik_cover.jpg",
        "profileImg": "pelnapara_avatar.jpeg",
        "rank": 5,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "pelnapara",
        "profileLink": "https://www.instagram.com/pelnapara/",
        "eBookTitle": "Tajlandia Z Dzieckiem - Część 1",
        "pages": 175,
        "regularPrice": 59,
        "location": "Tajlandia",
        "ebookDescription": "Czego dowiesz się z 1 części e-booka o Tajlandii?<br /><br />- Dlaczego Tajlandia to idealny kierunek na wakacje z dziećmi?<br />- Zorganizować wyjazd na własną rękę, czy z biurem podróży?<br />- Jak, gdzie i kiedy kupić bilety do Tajlandii, żeby nie przepłacać?<br />- Przegląd pogody i pór roku oraz jak radzić sobie z upałem?<br />- Wyrobienie wizy i dłuższy pobyt w Tajlandii<br />- Wszystko o pieniądzach – waluta, kantory, bankomaty i płatność kartą.<br />- Ceny – czy w Tajlandii rzeczywiście jest tak tanio i ile pieniędzy zabrać?<br />- Opis środków transportu i komunikacji.<br />- Wynajem skutera, auta i przewóz dzieci.<br />- Praca zdalna, Internet i zakup karty SIM.<br />- Produkty dla dzieci: pampersy, tubki, mleko modyfikowane etc<br />- Co spakować, ile ubrań zabrać i co z zabawkami?<br />- Jedzenie! Kuchnia tajska, propozycje dań dla dzieci i rozszerzanie diety<br />- Szczepienia, profilaktyka i lekarz medycyny podróży?<br />- Zagrożenia zdrowotne, choroby tropikalne, leczenie i jakie leki zabrać?<br />- Jakie ubezpieczenie wybrać do Tajlandii (30% zniżki)<br />- Sale zabaw, przedszkola i szkoły – co warto wiedzieć?<br />- Przydatne ciekawostki i co może wkurzać w Tajlandii?",
        "img": "pelnapara_thailand_cover.jpg",
        "profileImg": "pelnapara_avatar.jpeg",
        "rank": 6,
        "pdfStamp": "Correct",
        "edition": 1
    },
    {
        "author": "worldbymagda",
        "profileLink": "https://www.instagram.com/worldbymagda/",
        "eBookTitle": "Idealny Paryż - Zorganizuj Wymarzony Pobyt W Stolicy Francji",
        "pages": 294,
        "regularPrice": 39,
        "location": "Paryż",
        "ebookDescription": "Mieszkając w Polsce, za każdym razem, gdy odwiedzałam Paryż, zachwycałam się nim od nowa. Odkrywałam urocze kawiarenki, słynne muzea, romantyczne uliczki i malownicze parki. Nawet po 6 latach życia w tym mieście, Paryż nigdy mi się nie znudził. To magiczne miejsce, które ciągle mnie zaskakuje.\n\nDlatego postanowiłam stworzyć e-booka o Paryżu, aby podzielić się moimi ulubionymi miejscami i tajemnicami tego pięknego miasta. Mam nadzieję, że ta książka stanie się dla Ciebie inspiracją do odkrywania magicznej atmosfery paryskiego stylu życia. Razem zwiedzimy zabytki, kulturalne atrakcje i lokalne tajemnice, aby Twoja podróż do Paryża była wyjątkowa i niezapomniana. Chwyć więc croissanta i cafe au lait, a razem odkryjmy magię Paryża!\n\nPo 6 latach mieszkania w mieście miłości, stworzenie e-booka o Paryżu było dla mnie czymś naturalnym. Mam nadzieję, że ta książka i miejsca, które tu pokazuję staną się dla Ciebie początkiem wspaniałej przygody w paryskim stylu art de vivre.\n\nChcę pokazać Ci wszystko to, co wiem o Paryżu. Pragnę zabrać Cię do miejsc, które skradły moje serce. Zaprosić Cię na najlepsze croissanty i zaprowadzić do perełek, których nie znajdziesz w zwykłych przewodnikach. Chcę, aby Twoja podróż była magiczna, wyjątkowa i niezapomniana.\n\nW tym ebooku wspólnie zwiedzimy wiele cudów Paryża, od jego zabytków i atrakcji kulturalnych po ukryte zakątki i lokalne tajemnice. Niezależnie od tego, czy planujesz romantyczny wypad, czy rodzinne wakacje, ten ebook pomoże Ci w pełni wykorzystać podróż do Paryża. Chwyć więc croissanta i cafe au lait i odkryjmy razem magię Paryża!",
        "img": "worldbymagda_cover.jpg",
        "profileImg": "worldbymagda_avatar.jpeg",
        "rank": 6,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "kawaiwyprawa",
        "profileLink": "https://www.instagram.com/kawaiwyprawa/",
        "eBookTitle": "Przewodnik Australia - Wszystko Co Musisz Wiedzieć Przed Podróżą",
        "pages": 124,
        "regularPrice": 49.99,
        "location": "Australia",
        "ebookDescription": "Australia, jak wyjechać – Co znajdziesz w kompendium?<br /><br />- Będziesz w stanie samodzielnie zorganizować wyjazd do Australii <br />- Dowiesz się, na jakie opcje wizy do Australii są dla Ciebie dostępne<br />- Poznasz tajniki kupna tańszych biletów lotniczych, unikniesz potencjalnych problemów z bagażem (australijskie prawo zakazu przewożenia określonych produktów),<br />- Poznasz obowiązkową deklarację podróżnika  oraz przygotujesz się na kontrolę lotniskową,<br />- Wyjaśniamy kwestie pracy oraz gdzie i jak jej szukać, podajemy kilkanaście konkretnych stron, grup i miejsc do znalezienia pracy w Australii, opisujemy jakie typy pracy są popularne na miejscu i czym się różnią,<br />- Dowiesz się jak wynająć mieszkanie, od spotkania na inspekcje po wyprowadzkę, krok po kroku. Opisujemy także co zrobić w przypadku braku referencji,<br />- Przeczytasz porównanie australijskich operatorów i wybierzesz najlepszy internet dla siebie,<br />- Przygotujesz się na podróż po Australii – poznasz listę spraw do załatwienia jeszcze w Polsce,<br />- Dowiesz się kiedy najlepiej przyjechać do Australii, jakie obszary wybrać i przeczytasz niezbędne informacje o podróży na antypody,<br />- Zaplanujesz kompleksowo swój budżet na Australię!<br />i wiele, wiele więcej!",
        "img": "kawaiwyprawa_cover.jpg",
        "profileImg": "kawaiwyprawa_avatar.jpeg",
        "rank": 6,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "polka_na_krecie",
        "profileLink": "https://www.instagram.com/polka_na_krecie/",
        "eBookTitle": "Kreta. Wyspa, która potrafi zawładnąć sercem",
        "pages": 42,
        "regularPrice": 39.99,
        "location": "Kreta",
        "ebookDescription": "Po 12 latach życia na wyspie przelałam swoją wiedzę tworząc książki o Krecie. \nKreta to największa wyspa Grecka. Ta książka to spojrzenie na królową wysp greckich oczami pasjonatki.<br/><br/>\nDzięki tej interaktywnej książce:<br/><br/>\n-dowiesz się jacy są Kreteńczycy,<br/>\n- ugotujesz dania z kuchni kreteńskiej, których nie znajdziesz w tawernach,<br/>\n- odkryjesz top 5 miejsc we wschodniej stronie wyspy,<br/>\n- zaplanujesz swoją podróż łącznie z budżetem, noclegiem i miejscowymi atrakcjami.<br/>\n<br/><br/>\nTen e-book jest dla wszystkich, którzy pierwszy raz wyruszają na podbój Krety, ale i dla tych co znają ją bardzo dobrze.",
        "img": "polka_na_krecie_cover.jpg",
        "profileImg": "polka_na_krecie_avatar.jpeg",
        "rank": 7,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "whereisiza",
        "profileLink": "https://www.instagram.com/whereisiza/",
        "eBookTitle": "Poradnik O Tym Jak Pojechać Do Emiratów Arabskich I Nie Zbankrutować?",
        "pages": 67,
        "regularPrice": 0,
        "location": "Emiraty Arabskie",
        "ebookDescription": "Poradnik podrózniczy o tym jak pojechac do<br />Emiratów Arabskich i nie zabankrutować: <br /><br />- Najlepszy widok na Burial Arab<br />- Wejściówki na baseny - jak?<br />- Atlantis waterpark za darmo?!<br />- Jak sie dostaé do Abu Dhabi za 50zł?<br />- Gdzie wybrać sie na drinka z widokiem<br />- Najlepsze miejsca na zdjęcia<br />I WIELE INNYCH!",
        "img": "whereisiza_cover.jpg",
        "profileImg": "whereisiza_avatar.jpeg",
        "rank": 8,
        "pdfStamp": "Removed",
        "edition": 1
    },
    {
        "author": "pieseknawalizkach",
        "profileLink": "https://www.instagram.com/pieseknawalizkach/",
        "eBookTitle": "Trójmiasto Z Psem",
        "pages": 62,
        "regularPrice": 39.9,
        "location": "Polska",
        "ebookDescription": "Zapraszam Was w podróż po miejscach, momentach, smakach i wspomnieniach trójmiejskich podróży z Chałką.<br /><br />\nMam nadzieję, że z naszą pomocą Wasz wyjazd z pupilem będzie równie wyjątkowy. Znajdziecie tu nasze ulubione kawiarnie, plaże\nczy miejscówki na udany wypad z psem na spacer.",
        "img": "pieseknawalizkach_cover.jpg",
        "profileImg": "pieseknawalizkach_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "damianmundoespanol",
        "profileLink": "https://www.instagram.com/damianmundoespanol",
        "eBookTitle": "Hiszpański W Podróży",
        "pages": 60,
        "regularPrice": 45,
        "location": "Hiszpania",
        "ebookDescription": "¡Hola! \n<br/><br/>\nW tym e-booku prezentuję Ci żywe oraz autentyczne wyrażenia przydatne w podróży:\n<br/>- Masz dość nieporozumień z lokalnymi mieszkańcami? \n<br/>- Brakuje Ci pewności siebie w komunikacji po hiszpańsku? Albo po prostu chcesz zaimponować drugiej połówce podczas waszej podróży?<br/><br/>\n8 rozdziałów tematycznych sprawi, że odnajdziesz się w każdej sytuacji, czy to na lotnisku, w hotelu, czy w restauracji.\n<br/><br/>\nMam na imię Damian i niecały miesiąc temu przeprowadziłem się do Buenos Aires, gdzie rozpocząłem swój projekt nauczania hiszpańskiego. Skupiam się na nauczaniu żywego języka w sposób łatwy i przyjemny dla ucha (dosłownie bo nauczam również mówionego hiszpańskiego w moich podcastach!)\n<br/><br/>\nZerknij na moje sociale!\n<br/>\nOwocnej nauki",
        "img": "damianmundoespanol_cover.jpg",
        "profileImg": "damianmundoespanol_avatar.jpg",
        "rank": 2,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "cromaniapl",
        "profileLink": "https://www.instagram.com/cromaniapl/",
        "eBookTitle": "Chorwacja – Wakacje Od A Do Z",
        "pages": 239,
        "regularPrice": 49.99,
        "location": "Chorwacja",
        "ebookDescription": "W e-booku “Chorwacja – Wakacje od A do Z” znajdziesz ponad 220 stron niezbędnych informacji, wskazówek i inspiracji przydatnych w czasie pobytu w Chorwacji: \n<br/><br/>\n- Informacje kiedy i jak zaplanować wyjazd?<br/>\n- Czy wybrać wyspy czy ląd? Jakie zakwaterowanie będzie najlepsze?<br/>\n- Wszystkie szczegóły dotyczące dojazdu samochodem i samolotem.<br/>\n- Wybór trasy, winiet, promy, ceny na miejscu, niezbędne dokumenty oraz kwestie zdrowia.<br/>\n- Regiony, plaże, najważniejsze atrakcje oraz najpiękniejsze wyspy.<br/>\n- Jakich potraw warto spróbować, jakie alkohole kupić, jakie pamiątki przywieźć, check-listy, co zabrać ze sobą.<br/>\n<br/><br/>\nMam nadzieję, że e-book ułatwi planowanie wakacji i zainspiruje do wybrania swojego miejsca w Chorwacji.",
        "img": "cromaniapl_cover.jpg",
        "profileImg": "cromaniapl_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "podrozujzagrosze",
        "profileLink": "https://www.instagram.com/podrozujzagrosze/",
        "eBookTitle": "Liguria",
        "pages": 59,
        "regularPrice": 20,
        "location": "Liguria",
        "ebookDescription": "E-book jest gotową organizacją podróży do włoskiej Ligurii. Odkryjesz z nim przepiękne miejsca, takie jak np. Cinque Terre i Portofino, czy odpoczniesz na egzotycznych plażach, takich jak Spiaggia di Paraggi. \n<br/><br/>\nKrok po kroku proponujemy, jak można wykorzystać kilka dni we Włoszech, mieszkając w Genui. Począwszy od biletów lotniczych, poprzez wszystkie transfery, ceny biletów, rozkłady jazdy, kończąc na informacjach praktycznych i wskazówkach. Twoim zadaniem jest jedynie zakup biletów lotniczych i.... już !",
        "img": "podrozujzagrosze_cover.jpg",
        "profileImg": "podrozujzagrosze_avatar.jpg",
        "rank": 3,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "hej.amigos",
        "profileLink": "https://www.instagram.com/hej.amigos",
        "eBookTitle": "Islandia Z Hejamigos",
        "pages": 182,
        "regularPrice": 69,
        "location": "Islandia",
        "ebookDescription": "E-book o Islandii to nasz autorski projekt skierowany do wszystkich osób, które były już na wyspie, chcą, planują lub zaplanują swoją podróż na Islandię. To kompendium wiedzy jaką udało się nam zgromadzić podczas 6 wypraw na Islandię. <br /><br />Pokażemy Ci jak i kiedy zobaczyć jedną z najpiękniejszych wysp na Ziemi. Dowiesz się nie tylko co zobaczyć, ale również jak taniej podróżować, jak szukać biletów, co zrobić gdy odwołają Twój lot czy jak przeżyć na surowej wyspie kilkanaście dni w samochodzie. Kraina ognia i lodu czeka na Ciebie.",
        "img": "hej.amigos_cover.jpg",
        "profileImg": "hej.amigos_avatar.jpeg",
        "rank": 3,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "jelenie_w_podrozy",
        "profileLink": "https://www.instagram.com/jelenie_w_podrozy/",
        "eBookTitle": "Jak Zrobić Prawo Jazdy W Tajlandii? I kupić motor",
        "pages": 50,
        "regularPrice": 39,
        "location": "Tajlandia",
        "ebookDescription": "Poświeciliśmy sporo czasu i pieniędzy by przejść cały proces wyrabiania prawa jazdy na motor w Tajlandii. Po wielu przygodach w końcu się udało, kupiliśmy motor i przemierzyliśmy na nim prawie 4 tys. km.  Chcielibyśmy podzielić się zebraną wiedzą i doświadczeniem  - dlatego powstał ten poradnik.<br /><br />\n\nJeśli zależy Ci na czasie i nie chcesz się denerwować podczas wizyt w urzedach w Tajlandii, ten e-book jest dla Ciebie!",
        "img": "jelenie_w_podrozy_cover.jpg",
        "profileImg": "jelenie_w_podrozy_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "siostry_wesolowskie",
        "profileLink": "https://www.instagram.com/siostry_wesolowskie/",
        "eBookTitle": "Hiszpańskie Przepisy, Które Kochamy",
        "pages": 43,
        "regularPrice": 29,
        "location": "Hiszpania",
        "ebookDescription": "Zapewnij sobie podróż przez kulinarną mapę Hiszpanii. Odkryj sekrety tego zachwycającego\nkraju dzięki naszemu e-bookowi zawierającemu 15 autentycznych hiszpańskich przepisów\nna wyjątkowe dania. <br /><br />\n\nZnajdziesz tu pomysły na obiad, przekąski czy desery. Poznaj tajniki\nprzygotowania tradycyjnych potraw kuchni hiszpańskiej, zaskocz się różnorodnością smaków\ntortilli, paelli czy churros.",
        "img": "siostry_wesolowskie_cover.jpg",
        "profileImg": "siostry_wesolowskie_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "creative.english.studio",
        "profileLink": "https://www.instagram.com/creative.english.studio/",
        "eBookTitle": "Twoja Podróżna Ściąga Czyli Dogadaj Się Na Wakacjach",
        "pages": 46,
        "regularPrice": 39,
        "location": "",
        "ebookDescription": "Jeśli za każdym razem przed wyjazdem za granicę obawiasz się że nie dogadasz się na recepcji w hotelu, lub kelner nie zrozumie twojego zamówienia, a zakupiony bilet do miejscowości X okaże się biletem w przeciwnym kierunku, ten e-book jet dla Ciebie!<br /><br />\n\nOsoby początkujące znajdą w nim wiele ciekawych i nowych zwrotów, średniozaawansowani i zaawansowani z kolei mogą potraktować go jako formę przypomnienia (a być może także i nauki nowych słówek).<br /><br />\n\nCo znajdziesz w środku:<br />\n\n- 4 rozdziały (w hotelu, w restauracji, na mieście, na lotnisku)<br />\n- 1-2 ćwiczenia w każdym rozdziale<br />\n-  Mnóstwo wyrażeń i zdań codziennego użytku<br />\n-  Przykładowe dialogi ze zwrotami, które możesz usłyszeć",
        "img": "creative.english.studio_cover.jpg",
        "profileImg": "creative.english.studio_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "ochmilano",
        "profileLink": "https://www.instagram.com/ochmilano/",
        "eBookTitle": "Och!Tour Ukryty Mediolan",
        "pages": 128,
        "regularPrice": 95,
        "location": "Mediolan",
        "ebookDescription": "Gdzie w Mediolanie mieszkała wiedźma? Na której mediolańskiej ulicy przebacza się wszystkie grzechy? Czym jest złamany ząb milanesi? Jaką tajemnicę skrywa zdesperowany dromader w koszyku? Gdzie w Mediolanie można spotkać magiczne zwierzęta? <br /><br />\n\nNa te i wiele innych zagadkowych pytań odpowiadam w OCH!TOURZE Ukryty Mediolan – naszym wspólnym spacerze, podczas którego obnażę przed Tobą najskrytsze tajemnice miasta!",
        "img": "ochmilano_cover.jpg",
        "profileImg": "ochmilano_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "szczytyafryki",
        "profileLink": "https://www.instagram.com/szczytyafryki/",
        "eBookTitle": "Poradnik Przed Podróżą Do Afryki – Tanzania, Kenia",
        "pages": 126,
        "regularPrice": 29,
        "location": "Tanzania, Kenia",
        "ebookDescription": "Poradnik przed podróżą do Afryki – niezbędny materiał, który pozwoli dokładnie zaplanować Twoją afrykańską przygodę. Kompletny poradnik przed podróżą do Tanzanii i Kenii jest najlepszym i jedynym na polskim rynku zbiorem praktycznych informacji przydatnych podczas planowania podróży do Afryki. <br /><br />\n\nZnajdziesz w nim wszystkie niezbędne informacje, które pozwolą Ci zaplanować własną podróż do wymarzonego miejsca w Afryce. Szczegółowe wskazówki, które pozwolą na zorganizowanie wakacji marzeń w Afryce, trekkingu na Kilimandżaro i Mount Kenya, safari w Kenii albo w Tanzanii, a także podróży na Zanzibar. Poznasz w nim protipy, dzięki którym obniżysz koszt podróży do Afryki.",
        "img": "szczytyafryki_cover.jpg",
        "profileImg": "szczytyafryki_avatar.jpg",
        "rank": 1,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "mewa_w_locie",
        "profileLink": "https://www.instagram.com/mewa_w_locie/",
        "eBookTitle": "Przewodnik Po Bieszczadach",
        "pages": 29,
        "regularPrice": 25,
        "location": "Polska",
        "ebookDescription": "Niesamowita przyroda, dzikość i historia na wyciągnięcie ręki. Zalesione szczyty, obłędne kolory,\nniezapomniane zachody słońca. Zwierzęta, których nie spotkasz w innych rejonach kraju i zachwyt nad światem na każdym kroku. Ten wyjątkowy kawałek Karpat to Bieszczady. \n<br /><br />\nDzięki temu mini przewodnikowi będzie ci łatwiej zaplanować wyjazd w Bieszczady i poczujesz magię tego miejsca na własnej skórze!",
        "img": "mewa_w_locie_cover.jpg",
        "profileImg": "mewa_w_locie_avatar.jpg",
        "rank": 4,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "specjalistkaodwakacji",
        "profileLink": "https://www.instagram.com/specjalistkaodwakacji/",
        "eBookTitle": "Przewodnik Oman",
        "pages": 94,
        "regularPrice": 79.99,
        "location": "Oman",
        "ebookDescription": "Kompletny przewodnik po Omanie, prezentujący zarówno historię, jak i praktyczne informacje dla podróżujących. Przedstawia starożytne korzenie Omanu, historię współczesnego państwa oraz jego rolę w polskiej turystyce. Zawiera praktyczne porady dotyczące najlepszego czasu na podróż, kulturę i zwyczaje Omańczyków, oraz propozycje miejsc do zwiedzania. <br /><br />\n\nDodatkowo, omawia przyszłość turystyki w Omanie, włączając plany rozwoju Salalah i nowości w ofercie turystycznej.",
        "img": "specjalistkaodwakacji_cover.jpg",
        "profileImg": "specjalistkaodwakacji_avatar.jpg",
        "rank": 2,
        "pdfStamp": "Removed",
        "edition": 2
    },
    {
        "author": "visualofadrian",
        "profileLink": "https://www.instagram.com/visualofadrian/",
        "eBookTitle": "Bali W 2 Tygodnie",
        "pages": 51,
        "regularPrice": 75,
        "location": "Bali",
        "ebookDescription": "“Bali w 2 tygodnie” to Twój osobisty przewodnik po magicznej wyspie, pełen autorskich zdjęć, opisów atrakcji oraz praktycznych wskazówek dotyczących podróżowania.<br /><br />Odkryj najważniejsze miejsca, kulturę Balijczyków i zanurz się w autentycznej atmosferze. Z tym e-bookiem rozpoczniesz przygotowania do swojej wymarzonej podróży na Bali!",
        "img": "visualofadrian_cover.jpg",
        "profileImg": "visualofadrian_avatar.jpg",
        "rank": 1,
        "pdfStamp": "Removed",
        "edition": 2
    }
];

export default ebooksList;