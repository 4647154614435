import styled from 'styled-components';


export const LogotypeWrapper = styled.div`
  display: flex;
  width: 240px;
  height: 60px;
  
  svg {
    width: 100%;
    height: auto;
    fill: ${ ( { theme } ) => theme.ui04 };
  }
`;

export default LogotypeWrapper;
