import React from 'react';

import { ButtonContent, ButtonWrapper } from '../styled';

const Button = ( {
                     as,
                     href,
                     rel,
                     variant = 'primary',
                     children,
                     isFullWidth = false,
                     isDisabled = false,
                     dataId,
                     dataValue,
                     onClick = () => {},
                 } ) => {

    const handleOnClick = (event) => {
        if (isDisabled) {
            event.preventDefault();
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push( {
                'event': 'button_click',
                'dataId': dataId,
                'dataValue': dataValue,
            } );
            onClick();
        }
    };

    return (
        <ButtonWrapper
            as={ as }
            data-id={ dataId }
            data-value={ dataValue }
            href={ href }
            rel={ rel }
            variant={ variant }
            isDisabled={ isDisabled }
            isFullWidth={ isFullWidth }
            onClick={ handleOnClick }
        >
            <ButtonContent>{ children }</ButtonContent>
        </ButtonWrapper> );
};

export default Button;
