import styled from 'styled-components';


const NavigationLink = styled.a`
  display: flex;
  background: none;
  border: none;
  outline: none;
  font-weight: ${ ( { theme } ) => theme.fontWeightBold };
  font-size: ${ ( { theme } ) => theme.fontSizeXXS };
  text-transform: uppercase;
  cursor: pointer;
  padding: ${ ( { theme } ) => theme.spacing08 } ${ ( { theme } ) => theme.spacing07 };
  color: ${ ( { theme } ) => theme.text02 };
  transition: all ${ ( { theme } ) => `${ theme.transitionTypeDefault } ${ theme.transitionFast }` };
  text-decoration: none;
  
  &:hover,
  &:focus {
    color: ${ ( { theme } ) => theme.text01 };
    text-decoration: underline;
  
  &:focus {
    outline: 2px solid ${ ( { theme } ) => theme.ui05 };;
  }
`;

export default NavigationLink;
