import styled from 'styled-components';


const FeatureBoxTitle = styled.div`
    display: block;
    font-size: ${ ( { theme, isFeatured } ) => isFeatured ? theme.fontSizeS : theme.fontSizeXS };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    color: ${ ( { theme } ) => theme.text02 };
    padding: 0;
    margin: ${ ( { theme } ) => theme.spacing05 } 0 0;
`;

export default FeatureBoxTitle;
