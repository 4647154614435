import styled from 'styled-components';


const FeatureBoxContent = styled.div.attrs(props => ({
    isFeatured: undefined,
}))`
    position: relative;
    width: 100%;
    border-radius: ${ ( { theme } ) => theme.borderRadiusS };
    background-color: ${ ( { theme } ) => theme.ui01 };
    box-shadow: ${ ( { theme, isFeatured } ) => isFeatured ? theme.boxShadowStrong : theme.boxShadow };
    text-align: center;
    min-width: 120px;
    max-width: 300px;
    min-height: 100px;
    padding: ${ ( { theme } ) => theme.spacing06 };
    z-index: ${ ( { theme } ) => theme.floor02 };
    transition: all ${ ( { theme } ) => `${ theme.transitionTypeDefault } ${ theme.transitionSlow }` };

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        max-width: 90%;
    }

    &:hover {
        box-shadow: ${ ( { theme } ) => theme.boxShadowStrong };
    }
}
`;

export default FeatureBoxContent;
