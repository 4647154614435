import styled from 'styled-components';

export const EbookDescription = styled.p`
    color: ${ ( { theme } ) => theme.text01 };
    line-height: ${ ( { theme } ) => theme.fontLinHeightRegular };
    width: 100%;
    display: block;
    text-align: left;
`;

export default EbookDescription;
