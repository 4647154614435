export const getUniqueByProperty = ( arr, property ) => {
    const uniqueValuesSet = new Set();

    return arr.filter( ( item ) => {
        const propertyValue = item[ property ];
        if ( !uniqueValuesSet.has( propertyValue ) ) {
            uniqueValuesSet.add( propertyValue );
            return true;
        }
        return false;
    } );
};

export default getUniqueByProperty;
