import styled from 'styled-components';


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export default ContentWrapper;
