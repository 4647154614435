import styled from 'styled-components';

const bubbleRadius = '25px';
const tailSize = '30px';

const CommentSpeechBubbleRight = styled.div`
    font-size: ${ ( { theme } ) => theme.fontSizeXS };
    line-height: 1.5;
    padding: calc(2 * ${ bubbleRadius } / 3);

    max-width: 300px;
    padding: calc(2 * ${ bubbleRadius } / 3);
    -webkit-mask: radial-gradient(${ tailSize } at 100% 0, #0000 98%, #000 102%) 100% 100% / calc(100% - ${ bubbleRadius }) ${ tailSize } no-repeat,
    conic-gradient(at ${ bubbleRadius } ${ bubbleRadius }, #000 75%, #0000 0) calc(${ bubbleRadius } / -2) calc(${ bubbleRadius } / -2) padding-box,
    radial-gradient(50% 50%, #000 98%, #0000 101%) 100% 0 / ${ bubbleRadius } ${ bubbleRadius } space padding-box;
    background: linear-gradient(135deg, #FE6D00, #1384C5) border-box;
    color: #fff;

    border-right: ${ tailSize } solid #0000;
    margin-left: ${ tailSize };
    place-self: end;
    

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        max-width: 300px;
    }
`;
export default CommentSpeechBubbleRight;