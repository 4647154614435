import styled from 'styled-components';


const CookieContentWrapper = styled.div`
    position: relative;
    max-width: 700px;
    margin: 100px auto;
    padding: ${ ( { theme } ) => theme.spacing07 };
`;

export default CookieContentWrapper;
