import styled from 'styled-components';


const FooterCopyright = styled.div`
    color: ${ ( { theme } ) => theme.text02 };
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    a {
        color: inherit;
        
        &:hover {
            text-decoration: underline;
        }
    }
    
`;

export default FooterCopyright;
