import styled from 'styled-components';


const CountdownUnit = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    color: ${ ( { theme } ) => theme.text02 };
    font-family: ${ ( { theme } ) => theme.fontFamilyPrimary };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeL };
    background: ${ ( { theme } ) => theme.ui01 };
    border-radius: ${ ( { theme } ) => theme.borderRadiusS };
`;

export default CountdownUnit;
