import styled from 'styled-components';


const Decor = styled.div`
    position: absolute;
    right: -28px;
    bottom: -28px;
    width: 80px;
    height: 80px;
    border-radius: 30px 0 10px 0;
    z-index: ${ ( { theme } ) => theme.floor01 };
    background: ${ ( { theme } ) => theme.ui03 };
`;

export default Decor;
