import styled from 'styled-components';


export const TestimonialsListWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing04 };
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 ${({ theme }) => theme.spacing06 };

    /** Added just for printing ebooks **/
    @media print {
        &:nth-child(6n) {
            page-break-after: always;
        }
    }
`;

export default TestimonialsListWrapper;
