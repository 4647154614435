import styled from 'styled-components';


export const BannerBigText = styled.span`
    font-size: ${ ( { theme } ) => theme.fontSizeM };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    color: ${ ( { theme } ) => theme.text02 };
`;

export default BannerBigText;
