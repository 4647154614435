import styled from 'styled-components';


const NavigationItemIcon = styled.div`
  height: 16px;
  width: 16px;
  border-left: 1px solid ${ ( { theme } ) => theme.ui07 };
  padding-left: ${ ( { theme } ) => theme.spacing06 };
  
  svg {
    height: 100%;
    width: auto;
    fill: currentColor;
  }
`;

export default NavigationItemIcon;
