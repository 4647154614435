import styled from 'styled-components';


const PreviewTitle = styled.div`
  font-size: ${ ( { theme } ) => theme.fontSizeXS };
  font-weight: ${ ( { theme } ) => theme.fontWeightBold };
  color: ${ ( { theme } ) => theme.text02 };
  padding: 0;
  margin: 0;
`;

export default PreviewTitle;
