import React from 'react';
import ReactCountdown from 'react-countdown';

import translations from '../constants/translations';

import { CountdownSeparator, CountdownWrapper, } from '../styled';

import CountdownItem from './CountdownItem';


const renderer = ( { days, hours, minutes, seconds, completed } ) => {
    return (
        <CountdownWrapper>
            <CountdownItem
                label={ days === 1 ? translations.countdownLabelDay : translations.countdownLabelDays }
                number={ days < 10 ? `0${ days }` : days }
            />
            <CountdownItem
                label={ hours === 1 ? translations.countdownLabelHour : translations.countdownLabelHours }
                number={ hours }
            />
            <CountdownSeparator>:</CountdownSeparator>
            <CountdownItem
                label={ minutes === 1 ? translations.countdownLabelMinute : translations.countdownLabelMinutes }
                number={ minutes }/>
            <CountdownSeparator>:</CountdownSeparator>
            <CountdownItem
                label={ seconds === 1 ? translations.countdownLabelSecond : translations.countdownLabelSeconds }
                number={ seconds }
            />
        </CountdownWrapper>
    );
};

const Countdown = ( { title, text, img, link, dateEnd } ) => {
    return (
        <ReactCountdown
            date={ parseInt( dateEnd ) }
            renderer={ renderer }
        />
    );
};

export default Countdown;
