import styled from 'styled-components';


const CountdownUnitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 ${ ( { theme } ) => theme.spacing09 };
`;

export default CountdownUnitWrapper;
