import styled from 'styled-components';


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${ ( { alignItems = 'center' } ) => alignItems };
    width: ${ ( { number = 1 } ) => ( !!number ? `calc(${ 100 / number }% - 48px)` : 'calc(20% - 48px)' ) };
    margin: 0 24px;
    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
        width: 100%;
    }
    
    /** Added just for printing ebooks **/
    @media print {
        &:nth-child(6n) {
            page-break-after: always;
        }
    }
`;

export default Col;
