import styled from 'styled-components';


const CookieButtonGroup = styled.div`
    display: grid;
    grid-gap: ${( { theme } ) => theme.spacing08 };
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    margin-top: ${( { theme } ) => theme.spacing03 };
`;

export default CookieButtonGroup;
