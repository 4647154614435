import React from 'react';

import { List, ListItem } from '@/components/List';
import { translations } from '@/constants';

const PricingContent = () => (
    <List>
        <ListItem>{ translations.pricingContentBulletPoint01 }</ListItem>
        <ListItem>{ translations.pricingContentBulletPoint02 }</ListItem>
        <ListItem>{ translations.pricingContentBulletPoint03 }</ListItem>
        <ListItem>{ translations.pricingContentBulletPoint04 }</ListItem>
        <ListItem>{ translations.pricingContentBulletPoint05 }</ListItem>
    </List>
);


export default PricingContent;
