import styled from 'styled-components';


export const ButtonTag = styled.div`
  color: ${ ( { theme, isDisabled } ) => isDisabled ? theme.text04 : theme.text03 };
  background: ${ ( { theme, isDisabled } ) => isDisabled ? theme.ui08 : theme.ui06 };
  font-size: ${ ( { theme } ) => theme.fontSizeS };
  border-radius: ${ ( { theme } ) => theme.borderRadiusS };
  padding: ${ ( { theme } ) => theme.spacing07 } ${ ( { theme } ) => theme.spacing08 };
`;

export default ButtonTag;
