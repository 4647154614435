import styled from 'styled-components';


const PreviewContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${ ( { theme } ) => `0 ${ theme.spacing07 } ${ theme.spacing08 } ${ theme.spacing07 }` };
  justify-content: space-between;
`;

export default PreviewContent;
