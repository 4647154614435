import { useEffect, useState } from 'react';

export const useWindowSize = () => {
    const [ windowSize, setWindowSize ] = useState( {
        width: window.innerWidth,
        height: window.innerHeight,
    } );

    useEffect( () => {
        // Function to update window size
        const updateWindowSize = () => {
            setWindowSize( {
                width: window.innerWidth,
                height: window.innerHeight,
            } );
        };

        // Attach the event listener when the component mounts
        window.addEventListener( 'resize', updateWindowSize );

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener( 'resize', updateWindowSize );
        };
    }, [] ); // Empty dependency array ensures the effect runs only once on mount

    return windowSize;
};

export default useWindowSize;