export const getColor = ( { variant, theme } ) => {
    switch ( variant ) {
        case 'light':
            return theme.ui01;
        case 'dark':
            return theme.ui02;
        case 'transparent':
            return 'transparent';
        default:
            return theme.ui01;
    }
};

export default getColor;