import styled from 'styled-components';


const NavigationList = styled.ul`
    top: 0;
    right: ${ ( {
        isMobile,
        isNavigationOpen
    } ) => ( isMobile && isNavigationOpen ) ? '0' : ( isMobile ? '-100%' : '0' ) };
    position: ${ ( { isMobile } ) => isMobile ? 'fixed' : 'relative' };
    height: ${ ( { isMobile } ) => isMobile ? '100%' : 'auto' };
    width: ${ ( { isMobile } ) => isMobile ? '100%' : 'auto' };
    background: ${ ( { theme, isMobile } ) => isMobile ? theme.ui01 : 'transparent' };
    list-style-type: none;
    display: flex;
    flex-direction: ${ ( { isMobile } ) => isMobile ? 'column' : 'row' };
    margin: 0;
    padding: ${ ( { theme, isMobile } ) => isMobile ? `${ theme.spacing05 } ${ theme.spacing07 }` : '0' };
    transition: right ${ ( { theme } ) => theme.transitionFast };
    z-index: ${ ( { theme } ) => theme.floor03 };
`;

export default NavigationList;
