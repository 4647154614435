import React from 'react';

import Text from '@/components/Text';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';

import { globals, paths } from '@/constants';

import translations from '../constants/translations';
import { ContentWrapper, Decor, ImageWrapper } from '../styled';

const AboutUs = () => (
    <Section variant="dark">
        <Row>
            <Col number={ 2 }>
                <ContentWrapper>
                    <Headline>
                        { translations.aboutUsHeadline }
                    </Headline>
                    <Text size="medium">
                        Hej!
                    </Text>
                    <Text size="medium">
                        Jesteśmy czwórką przyjaciół - <a href="https://www.instagram.com/dieticianontheroad/"
                                                         rel="noreferrer" target="_blank">Asia</a>, <a
                        href="https://www.instagram.com/wspolnieprzezswiat"
                        rel="noreferrer" target="_blank">Zuza</a>, <a
                        href="https://www.instagram.com/dieticianontheroad/"
                        rel="noreferrer" target="_blank">Szymon</a> i <a
                        href="https://www.instagram.com/wspolnieprzezswiat"
                        rel="noreferrer" target="_blank">Bartek</a>,
                        których łączy wspólna pasja - podróże!
                    </Text>
                    <Text size="medium">
                        Poznaliśmy się w Meksyku, gdzie spędziliśmy wspaniały czas,
                        opowiadając o swoich podróżniczych przygodach.
                    </Text>
                    <Text size="medium">
                        Po powrocie do domu, wciąż utrzymywaliśmy stały kontakt, co zaowocowało wspólnymi pomysłami -
                        jak
                        ułatwić życie pasjonatom podróży takim jak my!
                    </Text>
                    <Text size="medium">
                        Tak narodził się pomysł stworzenia paczki podróżniczych
                        e-booków - {globals.SITE_NAME}
                    </Text>
                </ContentWrapper>
            </Col>
            <Col number={ 2 }>
                <ImageWrapper>
                    <img src={ `${ paths.ASSETS }/about_us.jpg` } alt={ globals.SITE_NAME }/>
                    <Decor/>
                </ImageWrapper>
            </Col>
        </Row>
    </Section>
);


export default AboutUs;
