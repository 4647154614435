export const sizing = {
    spacing01: '120px',
    spacing02: '100px',
    spacing03: '80px',
    spacing04: '60px',
    spacing05: '40px',
    spacing06: '20px',
    spacing07: '16px',
    spacing08: '8px',
    spacing09: '4px',
    borderRadiusM: '24px',
    borderRadiusS: '12px',
};
export default sizing;
