import styled from 'styled-components';


const CountdownWrapper = styled.div`
    display: flex;
    margin: 0 ${ ( { theme } ) => theme.spacing09 };
    padding: ${ ( { theme } ) => theme.spacing08 } 0;
`;

export default CountdownWrapper;
