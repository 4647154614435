import styled from 'styled-components';

const getSpacing = ( theme, spacing ) => {
    switch ( spacing ) {
        case 'medium':
            return theme.spacing03;
        case 'small':
            return theme.spacing05;
        case 'none':
            return 0;
        default:
            return theme.spacing03;
    }
};

export const SectionContent = styled.div`
  padding: ${ ( { theme, spacing } ) => getSpacing( theme, spacing ) } 0;
  margin: 0 auto;
  max-width: 1200px;
  width: 1200px;
`;

export default SectionContent;
