export const testimonialsList = [
    "Fantastyczna sprawa! Tyle wiedzy za tak małą cenę, wow ❤️ jak tylko o tym usłyszałam to od razu kupiłam, a fakt że z tego jeszcze poszły pieniądze na zwierzaki to już w ogóle sztos 😍 Super jest to, że paczka jest tak różnorodna i każdy znajdzie coś dla siebie. O kuchni, o podróży z dziećmi, o łączeniu pracy i podróży, jak zacząć, no i też kierunki różne - Ameryka, Azja, Afryka, Europa, Australia, no i jeszcze dla Polski starczyło miejsca 😱 totalny sztos! Nie zapominajmy także o tym ile niesamowitych twórców poznałam i od razu zaobserwowałam ich ciekawe profile 😁 Nie sądziłam, że ta paczka będzie tak bardzo super, a przeszła moje najśmielsze oczekiwania ❤️",
    "Jest genialna!😍😍😍 Bardzo Wam wszystkim, drodzy twórcy, dziękuję! Tyle wartościowych i ciekawych ebooków za taką cenę… bajka! - już przeczytałam kilka i nie mogę się doczekać, aż przeczytam je wszystkie. Szczerze to widzę tylko jedną wadę: wszystkie są tak ciekawe, że nie wiadomo, który czytać najpierw!😂🤩 Kocham podróże, od dwóch lat zwiedzam świat głównie solo (jestem teraz w kilkumiesięcznej podróży), a kierunki opisane w ebookach są na mojej liście podróżniczej w najbliższych miesiąciach. Bardzo Wam wszystkim dziękuję, Wasze prace czyta się z przyjemnością i uśmiechem na ustach!🥰😁",
    "Ta paczka naprawdę miło mnie zaskoczyła. E booki są świetnie napisane ! Są aktualne odnośniki na strony internetowe, wycieczki z dziecmi, trasy rozne. Daliście mi tak wiele pomysłów na te wakacje, że nie wiemy z mężem na co się zdecydować 🫣 najlepiej wydane 200 złotych. Dzięki!!😉",
    "Bardzo przydatna rzecz, szczególnie, że chce zacząć podróżować z małym dzieckiem ",
    "Świetny pomysł!! Dało mi to mega kopa do tego, aby zacząć podróżować i wyciskać z życia ile się da! Jest to mega pigułka najważniejszych informacji i miejsc! Czaaaad!! 🔥🔥🔥🔥",
    "Świetna, mnóstwo przydatnych treści. Zakupiłam już dzięki niej i trickom z e-bookow dwa wyjazdy pierwszy w ten piątek a kolejny w kwietniu. Poznałam także ciekawych twórców. Nie żałuję wydanych pieniędzy. ",
    "To mój najlepszy zakup e-booków ever! Niesamowita ilość treści. A dodatkowo serce rośnie jak ludzie i twórcy się wspierają i łączą siły 🫶🏻 Dzięki Wam za to! ",
    "Swietna sprawa! Wiele ebooków które chciałam o tak kupić, a dzięki paczce poznałam też kilku nowych twórców ❤️",
    "Coś wspaniałego. Ogrom wiedzy i praktycznych porad! Jestem zachwycona tą inicjatywą dzięki której mogłam w mega niskiej cenie zdobyć tyle wiedzy i jeszcze przyczynić się do realnej pomocy zwierzakom♥️",
    "Świetna! Niesamowita! Jest kilka ebooków, które chciałam zakupić. Kilka, o których nawet nie słyszałam a wydają się ultra ciekawe. I ta cena😮",
    "Jestem mile zaskoczona tym pomysłem, uważam paczkę za super rozwiązanie. Dzięki niej poznałam wielu nowych twórców ",
];

export default testimonialsList;