import styled from 'styled-components';


const CountdownSeparator = styled.div`
    display: flex;
    color: ${ ( { theme } ) => theme.text03 };
    font-size: ${ ( { theme } ) => theme.fontSizeL };;
    margin: 0;
    align-items: flex-end;
    padding-bottom: 10px;
    animation: blink 1s linear infinite;
    
    @keyframes blink {
        25% {
            opacity: 0.5;
        }
        25% {
            opacity: 0.5;
        }
        50% {
            opacity: 0;
        }
        75% {
            opacity: 0.5;
        }
    }
    
`;

export default CountdownSeparator;
