import React, { useState } from 'react';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Headline from '@/components/Headline';
import Text from '@/components/Text';

import { CookieButtonGroup, CookieContentWrapper } from '../styled';
import translations from '../constants/translations';
import { globals } from '@/constants';

import { PRIVILEGE_DENIED, PRIVILEGE_GRANTED } from '../constants';

const CookieConsent = () => {
    const [ isShowMoreVisible, setIsShowMoreVisible ] = useState( false );
    const [ isModalOpen, setToggleModal ] = useState( true );

    const setConsentPrivilege = ( privilege ) => ( {
        'ad_user_data': privilege,
        'ad_personalization': privilege,
        'ad_storage': privilege,
        'analytics_storage': privilege,
        'personalization_storage': privilege,
        'functionality_storage': privilege,
        'security_storage': privilege,
    } );

    const handleAcceptAll = () => {
        const consent = setConsentPrivilege( PRIVILEGE_GRANTED );
        window.gtag( 'consent', 'update', consent );
        localStorage.setItem( globals.COOKIE_CONSENT, JSON.stringify( consent ) );
        handleCloseModal();
    };

    const handleAcceptEssential = () => {
        const consent = setConsentPrivilege( PRIVILEGE_DENIED );
        window.gtag( 'consent', 'update', consent );
        localStorage.setItem( globals.COOKIE_CONSENT, JSON.stringify( consent ) );
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setToggleModal( !isModalOpen );
    };

    const handleShowMore = () => {
        setIsShowMoreVisible( !isShowMoreVisible );
    };

    return (
        <Modal
            open={ isModalOpen }
            closeIcon={ null }
            onClose={ handleCloseModal }
            closeOnOverlayClick={ false }
            closeOnEsc={ false }
            customStyles={ {
                closeIcon: {
                    display: 'none',
                },
            } }

        >
            <CookieContentWrapper>
                <Headline>{ translations.cookieHeadline }</Headline>
                <Text size="medium">
                    Na naszej stronie używamy trzech rodzajów plików cookie. Pliki cookie niezbędne do działania oraz
                    analityczne nie są używane w celach marketingowych ani reklamowych. <button
                    onClick={ handleShowMore }>Zobacz
                    wyjaśnienie naszych ciasteczek 🍪.</button>
                    { isShowMoreVisible &&
                        <ul>
                            <li><strong>Pliki cookie niezbędne</strong> zapewniają poprawne działanie naszej strony
                                internetowej.
                                Zawsze są włączone, ponieważ bez nich nie będzie można prawidłowo korzystać z naszej
                                strony.
                            </li>
                            <br/>
                            <li><strong>Pliki cookie analityczne</strong> pomagają nam zrozumieć, w jaki sposób
                                korzystasz z naszej strony, dzięki czemu możemy ją ulepszyć.
                            </li>
                            <br/>
                            <li>
                                <strong>Pliki cookie od innych firm</strong> pozwalają nam udostępniać informacje o
                                korzystaniu z naszej strony naszym partnerom (np. YouTube, media społecznościowe).
                                Następnie wykorzystują to do personalizacji reklam skierowanych do Ciebie.
                            </li>
                        </ul>
                    }
                </Text>
                <CookieButtonGroup>
                    <Button onClick={ handleAcceptAll }>{ translations.cookieButtonAcceptAll }</Button>
                    <Button
                        variant="secondary"
                        onClick={ handleAcceptEssential }>{ translations.cookieButtonAcceptEssential }</Button>
                </CookieButtonGroup>
            </CookieContentWrapper>
        </Modal>
    );
};

export default CookieConsent;