export const translations = {
    countdownLabelDays: 'Dni',
    countdownLabelDay: 'Dzień',
    countdownLabelHours: 'Godzin',
    countdownLabelHour: 'Godzina',
    countdownLabelMinutes: 'Minut',
    countdownLabelMinute: 'Minuta',
    countdownLabelSeconds: 'Sekund',
    countdownLabelSecond: 'Sekunda',
    countdownDoneText: 'Koniec',
};

export default translations;
