import styled from 'styled-components';


const FooterNavigationList = styled.ul`
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
`;

export default FooterNavigationList;
