import React, { useState } from 'react';

import FeatureBox from '@/components/FeatureBox';
import { Col, Row } from '@/components/Layout';
import Headline from '@/components/Headline';
import Section from '@/components/Section';


import { getUniqueByProperty, sortByProperty } from '@/utils';
import { paths, sortingOrder } from '@/constants';

import ebooksList from '@/data/ebooksList';

import translations from '../constants/translations';
import Avatar from '@/components/Avatar';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';

const Authors = () => {
    const maxElementsInRow = 4;
    const paginationStep = 8;
    const [ pagination, setPage ] = useState( paginationStep );
    const sortedEbookList = sortByProperty( {
        list: ebooksList,
        prop1: 'rank',
        prop2: 'author',
        order: sortingOrder.ASC });
    const authors = getUniqueByProperty( sortedEbookList, 'author' );
    const isDisplayedAll = pagination <= authors.length;

    const handleOnShowMore = () => {
        setPage( pagination + paginationStep );
    };

    return (
        <Section variant="dark">
            <Headline subHeading={ translations.authorsSubHeadline }>{ translations.authorsHeadline }</Headline>
            <Row>
                { authors.slice( 0, pagination ).map( ( item, index ) => {
                    const profileImgPath = item?.profileImg ? `${ paths?.AUTHORS_AVATAR }/${ item?.profileImg }` : `${ paths?.ASSETS }/avatar_placeholder.png`;

                    return (
                        <Col number={ pagination < maxElementsInRow ? pagination : maxElementsInRow } key={ index }>
                            <FeatureBox
                                dataId="author-link"
                                dataValue={ item.author }
                                as="a"
                                img={
                                    <Avatar>
                                        <img src={ profileImgPath } alt={ item?.author }/>
                                    </Avatar>
                                }
                                title={ `@${ item.author }` }
                                link={ item.profileLink }/>
                        </Col>
                    );
                } ) }
            </Row>
            { isDisplayedAll && (
                <Row>
                    <Spacer size="small"/>
                    <Button variant="secondary" dataId="authors-button-show-more" onClick={ handleOnShowMore }>
                        { translations.authorsButtonShowMore }
                    </Button>
                </Row>
            )
            }
        </Section>
    );
}
    ;

    export default Authors;;
