import { ebookStats, globals } from '@/constants';
import { getBundlePrice } from '@/utils';
export const translations = {
    navigationEbooks: 'E-BOOKI',
    navigationFAQ: 'FAQ',
    navigationAbout: 'O TRAVEL PACK!',
    navigationTestimonials: 'OPINIE',
    navigationTermsAndConditions: 'Regulamin',
    navigationPrivacy: 'Polityka Prywatności',
    navigationButtonOpen: 'Pokaż menu',
    navigationButtonClose: 'Zamknij menu',
    bannerHeadline01: 'Przygotuj się do swoich kolejnych',
    bannerHeadline02: 'podróży.',
    bannerText01: `Odkryj tajniki podróżowania, które znajdziesz w `,
    bannerText02: ` niezwykłych e-bookach! Inspirujące opowieści ze świata, cenne wskazówki na tanie latanie, strategiczne pakowanie, kuchnie świata oraz przewodniki i gotowe plany podroży!`,
    bannerText03: `To wszystko znajdziesz w ${globals.SITE_NAME}! Dodatkowo z każdej sprzedanej paczki 9 zł trafia do Fundacji pomagającym zwierzętom.`,
    bannerButtonText: 'KUP PACZKĘ',
    commonButtonBuyNowText: 'KUP PACZKĘ TERAZ',
    commonButtonBuyNowTextWithPrice: `KUP PACZKĘ ZA ${getBundlePrice}`,
    pricingHeadlineItem01: globals.SITE_NAME,
    pricingHeadlineItem02: 'Cena Regularna',
    pricingContentBulletPoint01: 'Tipy podróżnicze',
    pricingContentBulletPoint02: `${ebookStats.EBOOK_TOTAL_DESTINATIONS} kierunków`,
    pricingContentBulletPoint03: `${ebookStats.EBOOK_TOTAL_PAGES} stron wiedzy`,
    pricingContentBulletPoint04: `${ebookStats.EBOOK_TOTAL_EBOOKS_TYPE_STORY} opowieści ze świata`,
    pricingContentBulletPoint05: `${ebookStats.EBOOK_TOTAL_EBOOKS_TYPE_FOOD} stron przepisów kuchni świata`,
    footerCountdownText: 'Taka okazja się drugi raz nie trafia, Pośpiesz się!',
    footerCountdownTextCompleted: 'Tym razem samolot już naprawdę odleciał. Oferta zakończona!',
};

export default translations;
