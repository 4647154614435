// import { featureFlags } from '@/constants';
// import { getFeatureFlag } from '@/utils/index';

/**
 *
 * To disable the button it's required to add
 * getFeatureFlag( featureFlags.FEATURE_FLAG_SHOW_BUTTON_BUY ) !== 'correct';
 */
const isButtonBuyDisabled = () => true;

export default isButtonBuyDisabled;