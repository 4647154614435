import { useEffect, useState } from 'react';

export const useScrollPosition = () => {
    const [ scrollPosition, setScrollPosition ] = useState( 0 );
    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const threshold = 2;
    useEffect( () => {
        const updateScrollPosition = () => {
            setScrollPosition( Math.round(window.scrollY + window.innerHeight + threshold));
        };

        window.addEventListener( 'scroll', updateScrollPosition );

        return () => {
            window.removeEventListener( 'scroll', updateScrollPosition );
        };
    }, [] );

    const isPageBottom = scrollPosition >= documentHeight;
    return { scrollPosition, isPageBottom };
};

export default useScrollPosition;