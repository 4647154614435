import translations from './translations';
import {MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER, } from '@/constants/modules';

export const NAVIGATION = [
    {
        home: {
            id: 'ebooks',
            url: '/#ebooks',
            label: translations.navigationEbooks,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE ],
        },
    },
    {
        faq: {
            id: 'faq',
            url: 'faq',
            label: translations.navigationFAQ,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER ],
        },
    },
    {
        testimonials: {
            id: 'opinie',
            url: '/#opinie',
            label: translations.navigationTestimonials,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
        },
    },
    {
        about: {
            id: 'o-travel-pack',
            url: 'o-travel-pack',
            label: translations.navigationAbout,
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER],
        },
    },
    {
        social: {
            url: 'https://www.instagram.com/travelpack.shop',
            label: 'IG',
            module: [ MODULE_HEADER, MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
        },
    },
    {
        terms: {
            url: 'docs/travel_pack_regulamin.pdf',
            label: translations.navigationTermsAndConditions,
            module: [ MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
        },
    },
    {
        privacy: {
            url: 'docs/travel_pack_polityka_prywatnosci.pdf',
            label: translations.navigationPrivacy,
            module: [ MODULE_HEADER_MOBILE, MODULE_FOOTER ],
            isExternal: true,
        },
    },
    // ... other route objects
];

export const TRAVEL_PACK_URL_BUY = 'https://sowl.co/HwXy8'