import React, { useState } from 'react';

import { modules, routes, translations } from '@/constants';
import { NavigationItem, NavigationLink, NavigationList, NavigationToggleButton, NavigationWrapper } from '../styled';
import { ReactComponent as MenuIcon } from '@/assets/menu_icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/close_icon.svg';
import { useWindowResize } from '@/hooks';

const Navigation = ( { items } ) => {
    const [ isNavigationOpen, setToggleOpenNavigation ] = useState( false );
    const { isMobile } = useWindowResize();
    const toggleButtonTitle = isNavigationOpen ? translations.navigationButtonClose : translations.navigationButtonOpen;


    const headerRoutes = routes.NAVIGATION.filter( ( route ) => {
        const [ , routeObject ] = Object.entries( route )[ 0 ]; // Extract the inner route object
        return (
            isMobile ? routeObject.module.includes( modules.MODULE_HEADER_MOBILE ) :
                routeObject.module.includes( modules.MODULE_HEADER )
        );
    } );

    const handleOnToggleOpenNavigation = () => {
        setToggleOpenNavigation( !isNavigationOpen );
    };

    return (
        <NavigationWrapper>
            { isMobile && (
                <NavigationToggleButton isNavigationOpen={ isNavigationOpen } title={ toggleButtonTitle }
                                        onClick={ handleOnToggleOpenNavigation }>
                    { isNavigationOpen ? <CloseIcon/> : <MenuIcon/> }
                </NavigationToggleButton>
            ) }
            <NavigationList isMobile={ isMobile } isNavigationOpen={ isNavigationOpen }>
                { headerRoutes.map( ( route, index ) => {
                    const key = Object.keys( route )[ 0 ];
                    const { url, label, isExternal } = route[ key ];

                    return (
                        <NavigationItem isMobile={ isMobile } key={ index }>
                            <NavigationLink
                                data-id="navigation-link-header"
                                href={ url }
                                target={ isExternal ? '_blank' : '_self' }
                            >
                                { label }
                            </NavigationLink>
                        </NavigationItem>
                    );
                } ) }
            </NavigationList>
        </NavigationWrapper>
    );
};

export default Navigation;
