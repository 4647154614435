import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px); /* Adjust the initial slide distance */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default fadeIn;