import styled from 'styled-components';


const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: ${ ( { theme } ) => theme.spacing08 };
    background: ${ ( { theme } ) => theme.ui03 };
    border-top: 1px solid ${ ( { theme } ) => theme.ui05 };
`;

export default FooterWrapper;
