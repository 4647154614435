import React from 'react';

import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Section from '@/components/Section';
import Comment from '@/components/Comment';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';
import { isButtonBuyDisabled, isPromotionCompleted } from '@/utils';
import { routes, translations as commonTranslation } from '@/constants';
import { translations } from '../constants/translations';

import {
    EbookCover,
    EbookDescription,
    EbookDetailsContentWrapper,
} from '../styled';

const EbookDetails = ( { avatar, description, title, author, cover, pages, price, children, onClick } ) => {
    const getRandomTestimonial = () => {
        const randomIndex = Math.floor( Math.random() * translations.ebookTestimonials.length );
        return translations.ebookTestimonials[ randomIndex ];
    };
    // const hideBuyButton = isButtonBuyDisabled() || isPromotionCompleted();
    const hideBuyButton = isButtonBuyDisabled();
    return (
        <EbookDetailsContentWrapper>
            <Section spacing="small">
                <Row>
                    <Col number={ 2 }>
                        <EbookCover
                            src={ cover }
                            title={ title }
                            alt={ title }
                        />
                        <Spacer size="small"/>
                    </Col>
                    <Col number={ 2 }>
                        <Headline subHeading={ author }>{ title }</Headline>
                        <EbookDescription size="medium">
                            <span
                                dangerouslySetInnerHTML={ { __html: description } }
                            />
                        </EbookDescription>
                        {price  &&
                            <EbookDescription size="medium">
                                <strong>{translations.ebooksPrice} { price }</strong>
                            </EbookDescription>
                        }
                        <Spacer size="small"/>
                        <Comment text={ getRandomTestimonial() } avatar={ <img src={ avatar } alt={ author } title={ author }/> } orientation="left" />
                        <Spacer size="small"/>
                        <Button
                            dataId="button-buy-ebook-details"
                            dataValue={ author }
                            as="a"
                            rel="nofollow"
                            href={ !hideBuyButton ? routes.TRAVEL_PACK_URL_BUY : undefined }
                            isFullWidth
                            isDisabled={ hideBuyButton }
                        >
                            { commonTranslation.commonButtonBuyNowTextWithPrice }
                        </Button>
                    </Col>
                </Row>
            </Section>
        </EbookDetailsContentWrapper>
    );
};

export default EbookDetails;
