import React from 'react';
import { LogotypeWrapper } from '../styled/LogotypeWrapper';
import { LogotypeSimpleWrapper } from '../styled/LogotypeSimpleWrapper';
import { ReactComponent as FullLogotype } from '../assets/logotype.svg';
import { ReactComponent as SimpleLogo } from '../assets/logotypeSimple.svg';


export const Logotype = ( { isFullLogotype = true } ) => {
    if ( isFullLogotype ) {
        return (
            <LogotypeWrapper>
                <FullLogotype/>
            </LogotypeWrapper>
        );
    } else {
        return (
            <LogotypeSimpleWrapper>
                <SimpleLogo/>
            </LogotypeSimpleWrapper>
        );
    }
};

export default Logotype;
