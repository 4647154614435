import styled from 'styled-components';


const getSpacing = ( theme, size ) => {
    switch ( size ) {
        case 'medium':
            return theme.spacing03;
        case 'small':
            return theme.spacing05;
        case 'super-small':
            return theme.spacing06;
        case 'none':
            return 0;
        default:
            return theme.spacing03;
    }
};

export const Spacer = styled.span`
  width: 100%;
  display: block;
  margin-top: ${ ( { theme, size } ) => getSpacing( theme, size ) };
`;

export default Spacer;
