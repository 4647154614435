import React from 'react';

import Text from '@/components/Text';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import Spacer from '@/components/Spacer';
import { Col, Row } from '@/components/Layout';


import translations from '../constants/translations';
import { ContentWrapper} from '../styled';

const Confirmation = () => (
    <Section variant="dark">
        <Row>
            <Col number={ 2 }>
                <ContentWrapper>
                    <Headline subHeading="TravelPack">
                        { translations.aboutUsHeadline }
                    </Headline>
                    <Text size="medium" align="center">
                        Dziękujemy za zakup TravelPack. Sprawdź swojego maila, aby pobrać e-booki. Miłej lektury! ✈️🌏
                    </Text>
                    <Spacer size="medium" />
                </ContentWrapper>
            </Col>
        </Row>
    </Section>
);


export default Confirmation;
