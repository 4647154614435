import styled from 'styled-components';


const FeatureBoxText = styled.span`
  font-size: ${ ( { theme } ) => theme.fontSizeXXS };
  font-weight: ${ ( { theme } ) => theme.fontWeightRegular };
  color: ${ ( { theme } ) => theme.text01 };
  padding: 0;
  margin: ${ ( { theme } ) => theme.spacing07 } 0;
`;

export default FeatureBoxText;
