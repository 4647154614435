import styled from 'styled-components';


const SubHeadingText = styled.p`
  font-size: ${ ( { theme } ) => theme.fontSizeXS };
  font-weight: ${ ( { theme } ) => theme.fontWeightBold };
  font-family: ${ ( { theme } ) => theme.fontFamilyPrimary };
  text-transform: uppercase;
  color: ${ ( { theme } ) => theme.text01 };
  padding: 0;
  margin: 0;
  
`;

export default SubHeadingText;
