import styled from 'styled-components';


const CountdownButton = styled.button`
    text-transform: uppercase;
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    color: ${ ( { theme } ) => theme.text03 };
`;

export default CountdownButton;
