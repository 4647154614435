import React from 'react';

import RowWrapper from '../styled/RowWrapper';

const Row = ( { justifyContent, children } ) => {
    const firstLevelChildren = React.Children.toArray( children ).filter( child =>
        React.isValidElement( child )
    );
    const numberOfChildren = firstLevelChildren.length;
    return (
        <RowWrapper justifyContent={ justifyContent } numberOfChildren={ numberOfChildren }>
            { children }
        </RowWrapper>
    );
};

export default Row;
