import styled from 'styled-components';


const StickyFooterText = styled.div`
    text-transform: uppercase;
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    color: ${ ( { theme } ) => theme.text03 };
`;

export default StickyFooterText;
