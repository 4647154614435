import React from 'react';

import {
    CountdownUnit,
    CountdownLabel,
    CountdownUnitWrapper,
} from '../styled';

const CountdownItem = ( { number, label } ) => (
    <CountdownUnitWrapper>
        <CountdownLabel>
            { label }
        </CountdownLabel>
        <CountdownUnit>{ number }</CountdownUnit>
    </CountdownUnitWrapper>
);

export default CountdownItem;
