export const getColor = ( { theme, variant, isDisabled } ) => {
    let backgroundColor = '';
    let color = '';

    if ( isDisabled ) {
        backgroundColor = theme.ui08;
        color = theme.text03;
    } else if ( variant === 'primary' ) {
        backgroundColor = theme.ui03;
        color = theme.text02;
    } else if ( variant === 'secondary' ) {
        backgroundColor = theme.ui01;
        color = theme.text02;
    } else if ( variant === 'action' ) {
        backgroundColor = theme.ui06;
        color = theme.text03;
    }
    return { backgroundColor, color };
};

export default getColor;