export const TRAVEL_PACK_PRICE = 219.00;
export const EBOOK_TOTAL_EBOOKS = 71;

export const TRAVEL_PACK_SIZE = '1.54 GB';
export const EBOOK_TOTAL_EBOOKS_TYPE_STORY = 5;
export const EBOOK_TOTAL_EBOOKS_TYPE_FOOD = 375;
export const EBOOK_TOTAL_DESTINATIONS = 38;
export const EBOOK_TOTAL_PAGES = 9969;
export const EBOOK_TOTAL_RECOMMENDATION = '+500';

export const EBOOK_TOTAL_VALUE = 4134;


export const EBOOK_SAVING = `${ Math.round( ( ( EBOOK_TOTAL_VALUE - TRAVEL_PACK_PRICE ) / EBOOK_TOTAL_VALUE ) * 100 ) }%`;

