export const translations = {
    summaryHeadline01: `Z Tymi Przewodnikami Poznasz `,
    summaryHeadline02: `Cały Świat.`,
    summaryTextMain01: `Bogata kolekcja e-booków zapewniająca unikalne spojrzenie na różnorodne kultury, malownicze krajobrazy i fascynujące tradycje.`,
    summaryTextMain02: `Bez względu na to, czy marzysz o egzotycznych podróżach, odkrywaniu historycznych miejsc, czy też delektowaniu się lokalnymi smakami, te przewodniki są kluczem do niezapomnianych przygód.`,
    summaryItemText01: 'KIERUNKÓW',
    summaryItemText02: 'STRON',
    summaryItemText03: 'POLECAJEK',

};

export default translations;
