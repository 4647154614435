import styled from 'styled-components';


const CountdownLabel = styled.span`
    font-size: ${ ( { theme } ) => theme.fontSizeXXS };
    font-weight: ${ ( { theme } ) => theme.fontWeightBold };
    text-transform: uppercase;
    color: ${ ( { theme } ) => theme.text03 };
    padding: 0;
    margin: ${ ( { theme } ) => theme.spacing09 } 0;
`;

export default CountdownLabel;
