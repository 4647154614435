import styled from 'styled-components';


const NavigationItem = styled.li`
  list-style-type: none;
  padding: ${ ( { theme, isMobile } ) => isMobile ? theme.spacing08 : 'initial' };
  
`;

export default NavigationItem;
