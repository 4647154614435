import styled from 'styled-components';


const ListItem = styled.li`
    position: relative;
    padding-left: 24px;
    margin-bottom:  ${ ( { theme } ) => theme.spacing08 };
    font-size:  ${ ( { theme } ) => theme.fontSizeXS };
    text-align: left;
    

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background: ${ ( { theme } ) => theme.ui06 };
    }
`;

export default ListItem;
