import styled from 'styled-components';


const FooterLogo = styled.div`
  margin: ${ ( { theme } ) => theme.spacing08 };
  width: 40px;
  height: 40px;
  svg {
    width: 100px;
    height: auto;
    fill: ${ ( { theme } ) => theme.ui01 };;
  }
`;

export default FooterLogo;
