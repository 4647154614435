import styled from 'styled-components';


const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: ${ ( { justifyContent = 'center' } ) => justifyContent };
  align-items: ${ ( { justifyContent = 'center' } ) => justifyContent };
`;

export default RowWrapper;
