import React from 'react';

import { useScrollPosition } from '@/hooks';
import { StickyFooterContent, StickyFooterWrapper } from '../styled';

const StickyFooter = ( { children } ) => {
    const { scrollPosition, isPageBottom } = useScrollPosition();
    const showFooter = scrollPosition > window.innerHeight;

    return (
        <StickyFooterWrapper showFooter={ showFooter } isPageBottom={isPageBottom}>
            <StickyFooterContent>
                { children }
            </StickyFooterContent>
        </StickyFooterWrapper>
    );
};

export default StickyFooter;
